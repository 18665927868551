<template>
  <label
    class="radio"
    :class="{
      disabled,
      slotted: useSlot,
      active: isActive,
    }"
  >
    <span class="radio__input">
      <input
        :key="updateVersion"
        :value="value"
        type="radio"
        :disabled="disabled"
        :checked="isActive"
        @change="onChange"
      >
      <span :style="{ backgroundColor: activeColor && isActive ? activeColor : '' }" />
    </span>
    <span
      v-if="$slots.default"
      class="radio__label black"
    >
      <slot />
    </span>
  </label>
</template>

<script setup lang="ts">
import { isEqual } from 'lodash-es';
import { useUpdate } from '../composables/useUpdate';
import { IRadioButton } from '../models/radioGroup.model';
import { computed } from 'vue';

const props = defineProps<{
  // Значение из компонента-родителя
  modelValue?: IRadioButton;
  // Значение компонента
  value?: IRadioButton;
  // Отключение радио кнопки
  disabled?: boolean;
  // Вкл/вкл  slotted класс
  useSlot?: boolean;
  // Цвет в активном состоянии
  activeColor?: string;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: IRadioButton): void;
}>();

const { updateVersion } = useUpdate();

const isActive = computed<boolean>(() => isEqual(props.modelValue, props.value));

function onChange() {
  emit('update:modelValue', props.value);
}
</script>

<style scoped lang="scss">
@import 'styles/base/common/variables';

.radio {
  display: inline-flex;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  font-style: normal;

  &.slotted {
    flex-direction: row-reverse;
    align-items: flex-start;
    border: 2px solid $dark-gray;
    border-radius: 8px;
    padding: 22.67px;

    &.active {
      border: 2px solid $text-dark-green;
    }

    .radio__input {
      flex-shrink: 0;
      margin-right: 0;
      margin-left: 30px;
    }
  }

  .black {
    color: $radio-text-c;
  }

  &__input {
    position: relative;
    width: 24px;
    height: 24px;
    margin-right: 12px;

    & > span {
      width: 100%;
      height: 100%;
      margin-right: 12px;
      background-color: $radio-bc;
      border-radius: 50%;
      display: inline-block;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        display: none;
        width: 8px;
        height: 8px;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        background: $radio-internal-bc;
        border-radius: 50%;
      }
    }

    & > input {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    & > input:checked + span {
      background-color: $radio-checked-bc;
      border: none;
    }

    & > input:checked + span::after {
      display: block;
    }
  }

  &:hover > &__input {
    & > span {
      border: 1px solid $radio-hover-br;
    }

    & > input:checked + span {
      background-color: $radio-text-c;
      border: none;
    }
  }

  &.disabled {
    cursor: not-allowed;

    & > .black,
    & > .light {
      color: $radio-hover-br;
    }
  }

  &.disabled > &__input {
    & > span {
      background-color: $radio-disabled-bc;
      border: none;

      &::after {
        display: none;
      }
    }

    & > input:checked + span {
      background-color: $radio-hover-br;
      border-color: $radio-hover-br;

      &::after {
        display: block;
      }
    }
  }

  &.disabled > &__label {
    cursor: not-allowed;
  }

  &__label {
    word-break: break-word;
  }
}
</style>
