<template>
  <div
    class="radio-group"
    :class="{
      'row-direction': row,
      'column-direction': column,
      'modal__radio-group': modalGroup,
    }"
  >
    <RadioButton
      v-for="(button, index) in buttons"
      :key="index"
      :value="button.value"
      :disabled="button.disabled || disabled"
      :model-value="modelValue"
      :use-slot="useSlot"
      :class="['radio-group__item', { 'active': modelValue === button.value }, buttonClass]"
      :active-color="button.activeColor"
      @update:model-value="onUpdateModelValue($event)"
    >
      <BaseTooltip
        :placement="EPopperPlacement.BottomStart"
        :show="button.disabled && button.tooltip ? undefined : false"
        hover
      >
        <div
          v-if="!useSlot"
          class="radio-group__item__text"
          :class="{
            'radio-group__item__text--disabled': button.disabled
          }"
        >
          {{ button.label }}
        </div>

        <slot
          v-else
          name="slotContent"
          :radio-content="button"
        />
        <template #content>
          <span
            class="radio-group__item__tooltip"
            v-html="sanitize(button.tooltip || '')"
          />
        </template>
      </BaseTooltip>
    </RadioButton>

    <div
      v-if="errorMessage"
      class="radio-group__error"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<script setup lang="ts">
import RadioButton from './RadioButton.vue';
import { IRadioButton } from '../models/radioGroup.model';
import { watch } from 'vue';
import BaseTooltip from '../components/BaseTooltip.vue';
import { sanitize } from '../directives/sanitize';
import { EPopperPlacement } from '../enums/popperPlacement.enum';
import { WatchSubscription } from '../utils/watchSubscription';
import useSSRUnsubscribeWatch from '../composables/useSSRUnsubscribeWatch';

const props = defineProps<{
  // Значение выбранной кнопки
  modelValue?: IRadioButton;
  // Горизонтальная ориентация
  row?: boolean;
  // Вертикальная ориентация
  column?: boolean;
  // Изменение стилей, растягивание кнопок в длину
  modalGroup?: boolean;
  // Массив радио кнопок
  buttons: Array<IRadioButton>;
  // Сообщение об ошибке
  errorMessage?: string;
  // Класс, применяемый к радиокнопкам
  buttonClass?: string;
  // slotted класс у кнопок
  useSlot?: boolean;
  // Отключить эмит
  isDisableEmitValueByBtnListChange?: boolean;
  // Отключить все кнопки
  disabled?: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: IRadioButton): void;
}>();

const watchSubscription = new WatchSubscription();

function emitUpdate(value: IRadioButton): void {
  emit('update:modelValue', value);
}

function checkExistsValueInButtons(): void {
  if (getButtonByValue(props.modelValue)) {
    return;
  }

  emitUpdate(getNotDisabledButton());
}

function onUpdateModelValue(event: IRadioButton): void {
  emit('update:modelValue', event);
}

function getButtonByValue(value: IRadioButton): IRadioButton | undefined {
  return props.buttons.find((button) => button === value);
}

function getNotDisabledButton(): IRadioButton | undefined {
  return props.buttons.find((button) => !button.disabled);
}

watchSubscription.add(
  watch(
    () => props.buttons,
    () => !props.isDisableEmitValueByBtnListChange && checkExistsValueInButtons(),
  ),
);

useSSRUnsubscribeWatch(watchSubscription);
</script>

<style scoped lang="scss">
@import 'styles/base/common/variables';

.modal__radio-group {
  margin-top: 20px;

  .radio-group__item {
    border: 1px solid $gray-200;
    padding: 16px 18px;
    border-radius: 6px;
    width: calc(50% - 10px);
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    &.active {
      border: 2px solid $text-dark-green;
    }
  }

  :deep(.radio__input) {
    margin-right: 0;
  }
}

.radio-group {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;

  :deep(.mm-tooltip.mm-tooltip--base) {
    .popper {
      max-width: unset !important;
    }
  }

  &.row-direction,
  & {
    flex-direction: row;
  }

  &.row-direction > &__item {
    margin-right: 20px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  &.column-direction {
    flex-direction: column;
  }

  &.column-direction > &__item {
    margin-bottom: 12px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__error {
    color: $radio-group-text-error-c;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    flex-basis: 100%;
    margin-top: 2px;
  }

  &__item {
    &__text {
      &--disabled {
        cursor: not-allowed !important;
      }
    }
  }
}
</style>
