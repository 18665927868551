export default class FeatureFlagsHelper {
  /**
   * Флаг отчета по ценам конкурентов для поставщика
   */
  public static get featureSupplierPriceReportFlag(): boolean {
    return FeatureFlagsHelper.processNumberVariableToBoolean(
      useRuntimeConfig()?.public.featureSupplierPriceReportFlag,
    );
  }

  /**
   * Флаг прямых поставок
   */
  public static get featureDirectDeliveriesFlag(): boolean {
    return FeatureFlagsHelper.processNumberVariableToBoolean(
      useRuntimeConfig()?.public.featureDirectDeliveriesFlag,
    );
  }

  /**
   * Флаг сотрудников поставщика
   */
  public static get featureSupplierEmployeesFlag(): boolean {
    return FeatureFlagsHelper.processNumberVariableToBoolean(
      useRuntimeConfig()?.public.featureSupplierEmployeesFlag,
    );
  }

  /**
   * Флаг последней мили
   */
  public static get featurePMFlag(): boolean {
    return FeatureFlagsHelper.processNumberVariableToBoolean(
      useRuntimeConfig()?.public.featurePMFlag,
    );
  }

  /**
   * Флаг ввода своего номера ГМ для поставщиков
   */
  public static get featureSupplierCustomCargoNumberFlag(): boolean {
    return FeatureFlagsHelper.processNumberVariableToBoolean(
      useRuntimeConfig()?.public.featureSupplierCustomCargoNumberFlag,
    );
  }

  /**
   * Флаг создания клиентом нового заказа на основе предыдущего
   */
  public static get featureClientRepeatOrderFlag(): boolean {
    return FeatureFlagsHelper.processNumberVariableToBoolean(
      useRuntimeConfig()?.public.featureClientRepeatOrderFlag,
    );
  }

  /**
   * Флаг отображения вкладки аналитики клиента
   */
  public static get featureClientAnalyticFlag(): boolean {
    return FeatureFlagsHelper.processNumberVariableToBoolean(
      useRuntimeConfig()?.public.featureClientAnalyticFlag,
    );
  }

  /**
   * Флаг отображения графика кол-ва и суммы заказов в аналитике клиента
   */
  public static get featureClientAnalyticCountSumChartFlag(): boolean {
    return FeatureFlagsHelper.processNumberVariableToBoolean(
      useRuntimeConfig()?.public.featureClientAnalyticCountSumChartFlag,
    );
  }

  /**
   * Флаг отображения графика лид таймов в аналитике клиента
   */
  public static get featureClientAnalyticLeadTimeChartFlag(): boolean {
    return FeatureFlagsHelper.processNumberVariableToBoolean(
      useRuntimeConfig()?.public.featureClientAnalyticLeadTimeChartFlag,
    );
  }

  /**
   * Флаг отображения графика статистика заявителей в аналитике клиента
   */
  public static get featureClientAnalyticApplicantsChartFlag(): boolean {
    return FeatureFlagsHelper.processNumberVariableToBoolean(
      useRuntimeConfig()?.public.featureClientAnalyticApplicantsChartFlag,
    );
  }

  /**
   * Флаг отображения графика подзаказов по статусам в аналитике клиента
   */
  public static get featureClientAnalyticSubOrdersStatusChartFlag(): boolean {
    return FeatureFlagsHelper.processNumberVariableToBoolean(
      useRuntimeConfig()?.public.featureClientAnalyticSubOrdersStatusChartFlag,
    );
  }

  /**
   * Флаг отображения графика поставщики по статусам в аналитике клиента
   */
  public static get featureClientAnalyticSuppliersGroupChartFlag(): boolean {
    return FeatureFlagsHelper.processNumberVariableToBoolean(
      useRuntimeConfig()?.public.featureClientAnalyticSuppliersGroupChartFlag,
    );
  }

  private static processNumberVariableToBoolean(variable: unknown): boolean {
    return Boolean(Number(variable));
  }
}
