export class CookieManager {
  static setCookieObject(cookieKey: string, info: unknown, maxAge?: number, useRawData?: boolean) {
    useCookie(cookieKey, {
      maxAge: maxAge,
    }).value = useRawData ? info as string : JSON.stringify(info);
  }

  static getCookieObject<T = string>(cookieKey: string): T | undefined {
    try {
      if (process.server) {
        const splitElement = useRequestHeaders(['cookie'])
          ?.cookie?.split(';')
          ?.find((keyValue) => keyValue?.trim()?.startsWith?.(cookieKey + '='))
          ?.split('=')?.[1];

        return JSON.parse(decodeURIComponent(splitElement)) as unknown as T;
      }

      return useCookie<T>(cookieKey)?.value;
    } catch (error) {
      return undefined;
    }
  }

  static clearCookie(cookieKey: string): void {
    useCookie(cookieKey).value = null;
  }

  static getCookieValueByName(cookie: string, name: string): string {
    return cookie?.match(new RegExp('(^| )' + name + '=([^;]+)'))?.[2] || '';
  }

  static async containsCookie(cookieName: string): Promise<boolean> {
    return await $fetch('/api/containsCookie', {
      method: 'GET',
      params: { cookieName },
      retry: 0,
    });
  }
}
