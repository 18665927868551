<template>
  <teleport to="body">
    <transition name="drawer-fade">
      <div
        v-if="visible"
        class="drawer__overlay"
        :style="{ zIndex: zIndex - 1 }"
        @click="onClose"
      />
    </transition>

    <transition :name="`drawer-slide-${position}`">
      <div
        v-if="visible"
        class="drawer__wrapper"
        :class="wrapperClasses"
        :style="wrapperStyles"
      >
        <div
          class="drawer__content"
          :class="[contentClass]"
        >
          <slot
            v-if="!hideHeader"
            name="header"
          >
            <div
              class="drawer__content-header"
              :class="[headerClass]"
            >
              <slot name="title">
                <h3 :class="[titleClass]">
                  {{ title }}
                </h3>
              </slot>

              <slot name="header-close-button">
                <button
                  class="btn btn-secondary drawer__close-btn"
                  :class="[closeButtonClass]"
                  :disabled="submitLoader?.value || disabledClose"
                  @click="onClose"
                >
                  Закрыть
                  <SvgIcon
                    :class="[closeButtonIconClass]"
                    src="navigation/close-20px"
                  />
                </button>
              </slot>
            </div>
          </slot>

          <slot />

          <slot
            v-if="!hideFooter"
            name="footer"
          >
            <div
              class="drawer__content-footer"
              :class="{
                'drawer__content-footer-no-border' : disableFooterLine,
                [footerClass]: footerClass
              }"
            >
              <button
                v-if="cancelText"
                class="btn btn-secondary"
                :disabled="submitLoader?.value || disableCancel"
                @click="onCancel"
              >
                {{ cancelText }}
              </button>

              <BaseTooltip
                arrow
                hover
                offset-distance="4"
                :disabled="!disableSubmit || !submitTooltipText"
                class="mm-tooltip mm-tooltip--base drawer__submit-btn-tooltip"
              >
                <LoaderButton
                  v-if="submitText"
                  class="drawer__submit-btn"
                  :loader="submitLoader"
                  :custom-disabler="disableSubmit"
                  :button-text="submitText"
                  :icon-src="EIconPath.IndicatorsProgressLightSvg"
                  :btn-classes="['btn-primary-with-save-background', 'mm-font-500']"
                  @confirm="onSubmit"
                />

                <template #content>
                  {{ submitTooltipText }}
                </template>
              </BaseTooltip>
            </div>
          </slot>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script lang="ts">
const initialPosition = EDrawerPosition.Right;
</script>

<script lang="ts" setup>
import { EDrawerPosition } from '../enums/drawer.enum';
import SvgIcon from './SvgIcon.vue';
import Loader from '../utils/loaderHelper.util';
import LoaderButton from './LoaderButton.vue';
import { EIconPath } from '../enums/iconPath.enum';
import { StyleValue } from 'vue';
import { WatchSubscription } from '../utils/watchSubscription';
import useSSRUnsubscribeWatch from '../composables/useSSRUnsubscribeWatch';

const props = withDefaults(
  defineProps<{
    // видимость компонента
    visible?: boolean;
    // ширина диалогового окна
    width?: string;
    // позицианирование
    position?: EDrawerPosition;
    // текст заголовка
    title?: string;
    // текст кнопки отмена
    cancelText?: string;
    // текст кнопки сабмит
    submitText?: string;
    // класс обертки контента
    contentClass?: string;
    // класс обертки футера
    footerClass?: string;
    // класс обертки заголовка
    headerClass?: string;
    // блокирование кнопки отмена
    disableCancel?: boolean;
    // блокирование кнопки принятия
    disableSubmit?: boolean;
    //удаление border-top у футера
    disableFooterLine?: boolean;
    // скрыть футер
    hideFooter?: boolean;
    // окно с закругленными краями
    rounded?: boolean;
    // zIndex для окна
    zIndex?: number;
    // блокирование кнопки закрытия панели
    disabledClose?: boolean;
    // Лоадер для submit кнопки
    submitLoader?: Loader;
    // Текст тултипа кнопки сабмита
    submitTooltipText?: string;
    // скрыть хидер
    hideHeader?: boolean;
    // класс обертки дравера
    wrapperClass?: string;
    // Класс наименования дравера
    titleClass?: string;
    // Класс кнопки закрыть в хидере
    closeButtonClass?: string;
    // Класс иконки в кнопке закрыть в хидере
    closeButtonIconClass?: string;
    // Максимальная ширина drawer
    maxWidth?: string;
    // Максимальная высота drawer
    maxHeight?: string;
  }>(),
  {
    width: '445px',
    position: initialPosition,
    cancelText: 'Отменить',
    submitText: 'Применить',
    zIndex: 21,
  },
);

const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'cancel'): void;
  (e: 'submit'): void;
}>();

const wrapperClasses = computed<Record<string, boolean>>(() => ({
  [props.position]: true,
  'rounded': props.rounded,
  [props.wrapperClass || '']: !!props.wrapperClass,
}));

const drawerDisplayedOnSide = computed(
  () => [EDrawerPosition.Left, EDrawerPosition.Right].includes(props.position),
);

const drawerDisplayedOnBaseline = computed(
  () => [EDrawerPosition.Bottom, EDrawerPosition.Top].includes(props.position),
);

const watchSubscription = new WatchSubscription();
const wrapperStyles = computed<StyleValue>(() => ({
  width: (drawerDisplayedOnSide.value && props.width) || undefined,
  maxWidth: props.maxWidth,
  height: (drawerDisplayedOnBaseline.value && props.width) || '100%',
  maxHeight: props.maxHeight,
  zIndex: props.zIndex,
}));

function onClose(): void {
  if (props.submitLoader?.value) {
    return;
  }
  emit('close');
}

function onCancel(): void {
  emit('cancel');
}

function onSubmit(): void {
  emit('submit');
}

function getClassListOfFirstHtmlTag(): DOMTokenList | null {
  if (process.server) {
    return null;
  }
  return document.getElementsByTagName('html')?.[0]?.classList;
}

function hideBodyScroll(): void {
  getClassListOfFirstHtmlTag()?.add('drawer--opened');
}

function displayBodyScroll(): void {
  getClassListOfFirstHtmlTag()?.remove('drawer--opened');
}

function toggleDisplayBodyScroll(newVisible: boolean) {
  if (process.server) {
    return;
  }
  if (newVisible) {
    return hideBodyScroll();
  }
  const drawerContainer = document.getElementsByClassName('drawer__overlay');
  if (drawerContainer?.length === 1) {
    displayBodyScroll();
  }
}

watchSubscription.add(
  watch(
    () => props.visible,
    (newVisible) => toggleDisplayBodyScroll(newVisible),
  ),
);

useSSRUnsubscribeWatch(watchSubscription);

onBeforeUnmount(() => {
  displayBodyScroll();
});
</script>

<style lang="scss" scoped>
@import 'styles/base/common/variables';

.drawer {
  &__overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    z-index: 20;
    background-color: rgba($color: $drawer-overlay-background-color, $alpha: 30%);
  }

  &__submit-btn {
    padding: 10px 28px;
  }

  &__close-btn {
    margin-left: auto;
  }

  &__wrapper {
    position: fixed;
    z-index: 21;
    transform: translateY(0%);
    box-shadow: -2px 2px 16px rgb(3 25 18 / 12%);
    max-height: 100%;
    max-width: 100%;
    background: $white;
    overflow: hidden;

    &.left {
      top: 0;
      left: 0;
      bottom: 0;
      height: 100%;
    }

    &.right {
      top: 0;
      right: 0;
      bottom: 0;
      height: 100%;
    }

    &.bottom {
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
    }

    &.top {
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
    }
  }

  &__content {
    background-color: $drawer-background-color;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 28px 40px;
      z-index: 40;
      background: $white;

      h3 {
        font-weight: 400;
        font-style: normal;
        font-size: 24px;
        line-height: 32px;
        color: $drawer-title-color;
      }
    }

    &-footer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 20px 40px 24px;
      border-top: 1px solid $drawer-footer-border-color;

      &-no-border {
        border-top: none;
      }

      button {
        flex: 1;
        margin-right: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:last-child {
          margin-right: 0;
        }
      }

      :deep(.drawer__submit-btn-tooltip) {
        .popper {
          max-width: 240px;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }

  &-fade-enter-active,
  &-fade-leave-active {
    transition: opacity 0.3s ease;
  }

  &-fade-enter-from,
  &-fade-leave-to {
    opacity: 0;
  }

  &-slide-left {
    &-enter-from,
    &-leave-to {
      transform: translateX(-100%);
    }

    &-enter-active,
    &-leave-active {
      transition: transform 0.3s;
    }
  }

  &-slide-right {
    &-enter-from,
    &-leave-to {
      transform: translateX(100%);
    }

    &-enter-active,
    &-leave-active {
      transition: transform 0.3s;
    }
  }

  &-slide-top {
    &-enter-from,
    &-leave-to {
      transform: translateY(-100%);
    }

    &-enter-active,
    &-leave-active {
      transition: transform 0.3s;
    }
  }

  &-slide-bottom {
    &-enter-from,
    &-leave-to {
      transform: translateY(100%);
    }

    &-enter-active,
    &-leave-active {
      transition: transform 0.3s;
    }
  }

  &__wrapper.left.rounded {
    border-radius: 0 20px 20px 0 !important;
  }

  &__wrapper.right.rounded {
    border-radius: 20px 0 0 20px !important;
  }

  &__wrapper.bottom.rounded {
    border-radius: 20px 20px 0 0 !important;
  }

  &__wrapper.top.rounded {
    border-radius: 0 0 20px 20px !important;
  }
}

@media only screen and (max-width: 767px) {
  .drawer {
    &__content {
      &-header {
        padding: 20px;

        .btn-secondary {
          font-size: 0;
          width: 36px;
          height: 36px;
          padding: 0;
          align-items: center;
          justify-content: center;

          svg {
            margin-left: 0;
          }
        }

        h3 {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
        }
      }

      &-footer {
        padding: 20px;

        .btn-secondary {
          display: none;
        }
      }
    }

    &__wrapper.left > &__content {
      border-radius: 0 20px 20px 0;
    }

    &__wrapper.right > &__content {
      border-radius: 20px 0 0 20px;
    }

    &__wrapper.bottom > &__content {
      border-radius: 20px 20px 0 0;
    }

    &__wrapper.top > &__content {
      border-radius: 0 0 20px 20px;
    }
  }
}
</style>
