import { INotification, INotificationsResponse, INotificationsStatusResponse } from 'models/notification.model';
import { AuthHelper } from 'utils/authHelper.util';

export class NotificationApiService {
  public static async getNotifications(page = 0, pageSize = 10): Promise<INotificationsResponse> {
    return AuthHelper.fetch<INotificationsResponse>('/api/v1/notifications', { params: { page: page.toString(), pageSize } });
  }

  public static async getNotificationsCount(): Promise<INotificationsStatusResponse> {
    return AuthHelper.fetch<INotificationsStatusResponse>('/api/v1/notifications/status');
  }

  public static async getNotification(notificationId: number): Promise<INotification> {
    return AuthHelper.fetch<INotification>(`/api/v1/notifications/${notificationId}`);
  }

  public static async readNotification(notificationId?: number): Promise<void> {
    return AuthHelper.fetch('/api/v1/notifications/read', { method: 'POST', params: { notificationId } });
  }
}
