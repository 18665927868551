export enum EBasketActionLoaderType {
  Save,
  Delete,
  Empty,
  ClearErp,
  Restore,
  Redistribute,
  BasketDistribution,
  FormingReport,
}
