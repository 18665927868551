import { ProviderService } from 'shared/models/providerService.model';
import DeliveryAddressModal from 'components/modals/DeliveryAddressModal.vue';
import DeliveryAddressAuthModal from 'components/modals/DeliveryAddressAuthModal.vue';
import { CookieManager } from 'shared/utils/cookieManager.util';
import { IDeliveryBase, IDeliveryCookie } from 'shared/models/bases.model';
import { IDeliveryAddress } from 'shared/models/deliveryAddress.model';
import { BasesApiService } from 'services/api/basesApi.service';
import { IDeliveryCity } from 'shared/models/cities.model';
import ModalManager from 'shared/services/modalManager.service';
import { ECookieKeys } from 'shared/enums/cookieKeys';
import { AddressApiService } from 'shared/services/api/addressApi.service';
import { AuthHelper } from 'utils/authHelper.util';
import { ObserverManager } from 'shared/services/observer-manager/observerManager.service';
import { Observer } from 'shared/services/observer-manager/observer.service';
import { UserHelper } from 'utils/userHelper.util';
import { useUserStore } from 'store/user.store';
import { ICategory } from 'shared/models/category.model';
import { useSearchGroupAccess } from 'composables/useGroup';
import Loader from 'shared/utils/loaderHelper.util';
import Notificator from 'shared/services/notificator.service';
import { clientSentry } from 'shared/utils/sentry/clientSentry.util';
import { ISubdivision } from 'models/client/subdivisions.model';
import { IUserBasisSettings } from '~/models/userBasisSettings.model';
import { NOT_FOUND_ERROR_CODE } from 'shared/constants/error.const';

export default class BasesService extends ProviderService {
  static readonly serviceName = 'basesService';
  private deliveryCities: Array<IDeliveryCity>;
  private isOpenModalBases = false;
  private isOpenModalBasesCity = false;
  private static _instance: BasesService;
  private _city = ref<string>();
  public cityFiasId = ref<string>();
  private _searchedAddresses = ref<Array<IDeliveryAddress>>();
  private _observerManager: ObserverManager = new ObserverManager();
  private _route = useRoute();

  public savingBasis = Loader.getReactiveInstance();
  public updatingUserBasis = Loader.getReactiveInstance();
  public checkingUserBasis = Loader.getReactiveInstance();

  private timer: ReturnType<typeof setTimeout> | null = null;
  private timerInterval?: number;
  private silentUpdateUserBasis = false;
  private manualUpdateUserBasis = false;

  public get base(): string {
    return this.userStore.getSelectedBaseName;
  }

  public get baseObject(): IDeliveryCookie {
    return this.userStore.getSelectedBase;
  }

  public get city(): string {
    return this._city.value;
  }

  public set city(city: string) {
    this._city.value = city;
  }

  public get userBases(): Array<IDeliveryBase> {
    return this.userStore.getUserBases;
  }

  public set userBases(userBases: Array<IDeliveryBase>) {
    this.userStore.setUserBases(userBases);
  }

  public get currentUserBase(): Partial<IDeliveryBase> | null {
    return this.userStore.getSelectedBase;
  }

  public set currentUserBase(userBases: Partial<IDeliveryBase>) {
    this.userStore.setSelectedBase(userBases);
    if (!this.silentUpdateUserBasis) {
      this._observerManager.notify();
    }
  }

  public get searchedAddresses(): Array<IDeliveryAddress> {
    return this._searchedAddresses.value;
  }

  public set searchedAddresses(searchedAddresses: Array<IDeliveryAddress>) {
    this._searchedAddresses.value = searchedAddresses;
  }

  public get currentPfmName(): string {
    return this.userStore.baseToSelect?.subdivisionName || '';
  }

  private constructor(
    private readonly userStore = useUserStore(),
  ) {
    super();
    this.setTimerInterval();
    this.startCheckUserBasis();
  }

  public static getInstance(): BasesService {
    if (!BasesService._instance) {
      BasesService._instance = new BasesService();
    }

    return BasesService._instance;
  }

  public getSilentUpdateUserBasis(): boolean {
    return this.silentUpdateUserBasis;
  }

  public setSilentUpdateUserBasis(value: boolean): void {
    this.silentUpdateUserBasis = value;
  }

  public getManualUpdateUserBasis(): boolean {
    return this.manualUpdateUserBasis;
  }

  public setManualUpdateUserBasis(value: boolean): void {
    this.manualUpdateUserBasis = value;
  }

  createBaseString(locality: string, street: string, name: string): string {
    let str = '';
    if (locality) {
      str += `${locality}, `;
    }
    if (street) {
      str += `${street}, `;
    }
    if (name) {
      str += `${name}`;
    }
    return str;
  }

  setBasesCityInfo(basesInfo: IDeliveryCookie): void {
    CookieManager.setCookieObject(ECookieKeys.DeliveryBasesCity, basesInfo);
  }

  setBasesCity(basesInfo: IDeliveryCookie): void {
    CookieManager.setCookieObject(ECookieKeys.DeliveryBases, basesInfo);
  }

  async getBaseById(id: number): Promise<IDeliveryBase> {
    return await BasesApiService.getBaseById(id);
  }

  async getUserBases(): Promise<Array<IDeliveryBase>> {
    this.userBases = await BasesApiService.getUserBases();
    return this.userBases;
  }

  async getSearchedAddress(searchValue: string): Promise<void> {
    this.searchedAddresses = (await AddressApiService.getSearchedAddress(searchValue)).filter((el) => el.data.cityType === 'г');
  }

  async getSearchedCity(searchValue: string): Promise<void> {
    this.searchedAddresses = await AddressApiService.getSearchedCity(searchValue);
  }

  public openSelectModal(modalManager: ModalManager, isUserLogin: boolean): void {
    const isMainGroupAccess = useSearchGroupAccess();

    if (isUserLogin && isMainGroupAccess.value) {
      return this.openBaseSelectModal(modalManager);
    }
    this.openCityChangeModal(modalManager);
  }

  openCityChangeModal(modalManager: ModalManager): void {
    if (this.isOpenModalBasesCity) {
      return;
    }

    this.isOpenModalBasesCity = true;
    const close = () => (this.isOpenModalBasesCity = false);
    const modalName = 'CityChangeModal';
    modalManager
      .show({
        bind: {
          title: 'Адрес доставки',
          'click-to-close': false,
          zIndex: 10,
          name: modalName,
        },
        component: DeliveryAddressModal,
        on: {
          close() {
            modalManager.hide(modalName);
            close();
          },
        },
      });
  }

  public initBases(modalManager: ModalManager): void {
    this.userStore = useUserStore();
    if (!this.userStore.isUserLoggedIn) {
      return;
    }

    const isMainGroupAccess = useSearchGroupAccess();
    const isShowBases = (!this.baseObject?.id || ((UserHelper.isAIM || !UserHelper.isIntegration) && !this.baseObject?.subdivisionName && this.userStore.userInfo?.baseToSelect?.subdivisions?.length))
      && !['client', 'redirect'].some((v) => this._route?.path.includes(v));

    if (isMainGroupAccess.value && isShowBases) {
      this.openBaseSelectModal(modalManager);
      return;
    }

    if (!UserHelper.isSupplier && isShowBases) {
      this.openCityChangeModal(modalManager);
      return;
    }
  }

  openBaseSelectModal(modalManager: ModalManager): void {
    if (this.isOpenModalBases) {
      return;
    }

    this.isOpenModalBases = true;
    const close = () => (this.isOpenModalBases = false);
    const userBases = this.userBases;
    const modalName = 'selectAuthUserBasesModal';
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const currentObject = this;
    modalManager
      .show({
        bind: {
          title: 'Адрес доставки',
          'click-to-close': false,
          contentText: 'Динамическая модалка',
          zIndex: 10,
          name: modalName,
        },
        component: DeliveryAddressAuthModal,
        on: {
          async close() {
            await nextTick();
            if (currentObject.currentUserBase?.id || !userBases.length) {
              modalManager.hide(modalName);
              close();
            }
          },
        },
      });
  }

  async getPopulation(): Promise<Array<IDeliveryCity>> {
    if (this.deliveryCities) {
      return this.deliveryCities;
    }

    return await AuthHelper.fetch('/api/v1/cities/population', undefined, true).then<Array<IDeliveryCity>>(
      (res: { cities: { count: number; items: Array<IDeliveryCity> } }) => {
        this.deliveryCities = res.cities?.items;
        return res.cities?.items;
      },
    );
  }

  getCurrentRegionFiasId(): string | undefined {
    return this.currentUserBase?.fiasId || this.cityFiasId.value;
  }

  public subscribe(callback: () => void): Observer {
    return this._observerManager.subscribe(callback);
  }

  public unsubscribe(observer: Observer): void {
    this._observerManager.unsubscribe(observer);
  }

  public isConflictBasesCategory(categoryData: ICategory | undefined): boolean {
    if (this.currentUserBase?.id && this.userStore.baseToSelect?.mpAllowedCategories?.length && categoryData) {
      const allowedCategoriesIds = this.userStore.baseToSelect.mpAllowedCategories;
      const isThisCategoryIncludes = allowedCategoriesIds.includes(Number(categoryData?.id));
      const isParentCategoryIncludes = categoryData?.parents?.some((parentCategory) => allowedCategoriesIds.includes(Number(parentCategory?.id)));
      if (allowedCategoriesIds.length && (!isThisCategoryIncludes && !isParentCategoryIncludes)) {
        return true;
      }
    }

    return false;
  }

  public async saveUserBasis(base: IDeliveryBase, subdivision?: ISubdivision): Promise<void> {
    this.savingBasis.activate();

    try {
      await BasesApiService.setUserBasisSettings(
        {
          basisId: base?.id,
          subdivisionId: subdivision?.id,
        },
      );

      this.setManualUpdateUserBasis(true);

      this.currentUserBase = {
        ...base,
        subdivisionName: subdivision?.name,
        subdivisionId: subdivision?.id,
      };
    } catch (error) {
      clientSentry.captureServiceException(
        error,
        'basesManager',
        undefined,
        {
          extra: {
            base,
          },
        },
      );
      Notificator.showDetachedNotification('Ошибка при сохранении выбранного адреса');
    } finally {
      this.savingBasis.deactivate();
      this.setManualUpdateUserBasis(false);
    }
  }

  public async updateCurrentUserBasis(): Promise<void> {
    this.updatingUserBasis.activate();

    try {
      const userBasisList = await BasesApiService.getUserBases();
      const userBasisSettings = await BasesApiService.getUserBasisSettings();
      this.setUserBasis(userBasisSettings, userBasisList);
    } catch (error) {
      clientSentry.captureServiceException(
        error,
        BasesService.getServiceName(),
        undefined,
        {
          extra: {
            userId: this.userStore.userId,
            currentUserBase: this.currentUserBase,
          },
        },
      );
      Notificator.showDetachedNotification('Ошибка при получении базиса пользователя');
    } finally {
      this.updatingUserBasis.deactivate();
    }
  }

  private setTimerInterval(): void {
    this.timerInterval = Number(useRuntimeConfig?.()?.public?.basisUpdateTimeout);
  }

  private setUserBasis(userBasisSettings: IUserBasisSettings, userBasisList: Array<IDeliveryBase>): void {
    const userBasis = userBasisList?.find?.((basis) => basis?.id === userBasisSettings.basisId);
    const subdivision = userBasis?.subdivisions?.find((subdivision) => subdivision?.id === userBasisSettings.subdivisionId);

    if (!userBasis) {
      return;
    }

    this.currentUserBase = {
      ...userBasis,
      subdivisionName: subdivision?.name,
      subdivisionId: subdivision?.id,
    };
  }

  public startCheckUserBasis(): void {
    this.clearCheckUserBasis();
    this.timer = setTimeout(
      async () => await this.checkUserBasis(),
      (this.timerInterval || 30) * 1000,
    );
  }

  public clearCheckUserBasis(): void {
    if (!this.timer) {
      return;
    }

    clearTimeout(this.timer);
    this.timer = null;
  }

  public async forceCheckUserBasis(silent = false): Promise<void> {
    this.clearCheckUserBasis();
    this.setSilentUpdateUserBasis(silent);
    await this.checkUserBasis();
    this.setSilentUpdateUserBasis(false);
  }

  private async checkUserBasis(): Promise<void> {
    if (this.checkingUserBasis.value
      || !this.userStore.isUserLoggedIn
      || !UserHelper.isClient
      || !process.client
    ) {
      return;
    }

    this.checkingUserBasis.activate();

    try {
      const userBasisSettings = await BasesApiService.getUserBasisSettings();

      if (userBasisSettings.basisId === this.currentUserBase?.id
        && (
          (userBasisSettings.subdivisionId ?? null) === (this.currentUserBase?.subdivisionId ?? null)
        )
      ) {
        return;
      }

      const userBasisList = await BasesApiService.getUserBases();
      this.setUserBasis(userBasisSettings, userBasisList);
    } catch (error) {
      if (error?.status !== NOT_FOUND_ERROR_CODE) {
        clientSentry.captureServiceException(
          error,
          BasesService.getServiceName(),
          undefined,
          {
            extra: {
              userId: this.userStore.userId,
              currentUserBase: this.currentUserBase,
            },
          },
        );
        Notificator.showDetachedNotification('Ошибка при проверке базиса');
      }
    } finally {
      this.checkingUserBasis.deactivate();
      this.startCheckUserBasis();
    }
  }
}
