export default {
  "erp-layout": () => import("/app/src/projects/marketplace/layouts/ERP-layout.vue").then(m => m.default || m),
  clear: () => import("/app/src/projects/marketplace/layouts/clear.vue").then(m => m.default || m),
  client: () => import("/app/src/projects/marketplace/layouts/client.vue").then(m => m.default || m),
  default: () => import("/app/src/projects/marketplace/layouts/default.vue").then(m => m.default || m),
  error: () => import("/app/src/projects/marketplace/layouts/error.vue").then(m => m.default || m),
  "marketplace-no-search-and-bottom-menu": () => import("/app/src/projects/marketplace/layouts/marketplace-no-search-and-bottom-menu.vue").then(m => m.default || m),
  marketplace: () => import("/app/src/projects/marketplace/layouts/marketplace.vue").then(m => m.default || m),
  "no-search": () => import("/app/src/projects/marketplace/layouts/no-search.vue").then(m => m.default || m),
  seller: () => import("/app/src/projects/marketplace/layouts/seller.vue").then(m => m.default || m),
  supplier: () => import("/app/src/projects/marketplace/layouts/supplier.vue").then(m => m.default || m),
  "transparent-head": () => import("/app/src/projects/marketplace/layouts/transparent-head.vue").then(m => m.default || m),
  "ui-kit": () => import("/app/src/shared/layouts/ui-kit.vue").then(m => m.default || m),
  "oci-marketplace": () => import("/app/src/projects/marketplace/modules/oci/layouts/oci-marketplace.vue").then(m => m.default || m)
}