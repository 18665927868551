import { IModalManager, IVueFinalModalInfo, IDynamicModalData } from '../models/modalManager.model';
import { ProviderService } from '../models/providerService.model';
import { $vfm, DynamicModalOptions } from 'vue-final-modal';
import Modal from '../components/modals/Modal.vue';
import DismissModal from '../components/modals/DismissModal.vue';
import { randomNumber } from '../utils/randomNumber.util';
import { IModalProps } from '../models/modal.model';

export default class ModalManager extends ProviderService implements IModalManager {
  static readonly serviceName = 'modalManager';
  private static _instance: ModalManager;

  public static getInstance() {
    return this._instance;
  }

  constructor() {
    if (ModalManager._instance) {
      return ModalManager._instance;
    }
    super();
    ModalManager._instance = this;
  }

  public async openAsyncConfirmModal(options: DynamicModalOptions, params?: Record<string, unknown>): Promise<void> {
    return new Promise((resolve, reject) => {
      const modalName = options?.bind?.name;
      return this.show(
        {
          ...options,
          on: {
            ok: function(this: ModalManager) {
              resolve();
              this.hide(modalName);
            }.bind(this),
            close: function(this: ModalManager) {
              this.hide(modalName);
              reject();
            }.bind(this),
            cancel: function(this: ModalManager) {
              this.hide(modalName);
              reject();
            }.bind(this),
          },
        },
        params,
      );
    });
  }

  public async openAsyncDismissModal(options: DynamicModalOptions, params?: Record<string, unknown>): Promise<void> {
    return new Promise((resolve) => {
      const modalName = options?.bind?.name;
      return this.show(
        {
          ...options,
          on: {
            ok: function(this: ModalManager) {
              resolve();
              this.hide(modalName);
            }.bind(this),
          },
          component: DismissModal,
        },
        params,
      );
    });
  }

  public showConfirmModal(
    titleText: string,
    contentText: string,
    okButtonText = 'Подтвердить',
    cancelButtonText = 'Отменить',
    props = {} as IModalProps,
  ): Promise<boolean | null> {
    const modalName = 'confirmActionModal';
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const currentObject = this;
    return new Promise((resolve) => currentObject
      .show({
        bind: {
          zIndex: '10000 !important',
          name: modalName,
          contentText,
          titleText,
          cancelButtonText,
          okButtonText,
          classes: 'modal fixed-bottom-modal',
          ...(props || {}),
        },
        component: Modal,
        on: {
          ok() {
            resolve(true);
            currentObject.hide(modalName);
          },
          cancel() {
            resolve(false);
            currentObject.hide(modalName);
          },
          close() {
            resolve(null);
            currentObject.hide(modalName);
          },
        },
      }),
    );
  }

  show(options: DynamicModalOptions, params?: Record<string, unknown>): Promise<{ name: string }> {
    const modalUniqueName = randomNumber(0, 1_000_000);
    if (options?.bind?.title) {
      options.bind.titleText = options.bind.title;
    }
    delete options?.bind?.title;
    return (
      $vfm.show(
        {
          component: Modal,
          ...options,
          bind: {
            lockScroll: true,
            name: modalUniqueName.toString(),
            ...options.bind,
          },
        },
        params,
      ) as unknown as Promise<Record<string, unknown>>
    ).then<{ name: string }>((result) => ({ ...result, name: modalUniqueName.toString() }));
  }

  hide(name: string): Promise<void> {
    return $vfm.hide(name) as unknown as Promise<void>;
  }

  hideAll(): Promise<void> {
    return $vfm.hideAll() as unknown as Promise<void>;
  }

  toggle(name: string | Array<string>, show?: boolean, params?: Record<string, unknown>): Promise<void> {
    return $vfm.toggle(name, show, params) as unknown as Promise<void>;
  }

  get(...names: Array<string>): Array<IVueFinalModalInfo> {
    return $vfm.get(...names);
  }

  getDynamicModals(): Array<IDynamicModalData> {
    return $vfm.dynamicModals as Array<IDynamicModalData>;
  }

  getOpenedModals(): Array<IVueFinalModalInfo> {
    return $vfm.openedModals;
  }

  getModals(): Array<IVueFinalModalInfo> {
    return $vfm.modals;
  }
}
