import { EBasketType } from 'enums/basket/basketType.enum';
import { EBasketStatus } from 'enums/basket/basketStatus.enum';
import { IBasketErrorText, IBasketLoader } from 'models/basket.model';
import { EBasketActionLoaderType } from 'enums/basket/basketLoaderInitiator.enum';

export const BASKET_TYPE: Record<EBasketType, string> = {
  [EBasketType.Base]: 'Ваш выбор',
  [EBasketType.Price]: 'Наилучшая цена',
  [EBasketType.Delivery]: 'Быстрая доставка',
};

export const BASKET_ERROR_TEXT: Partial<Record<EBasketStatus, IBasketErrorText>> = {
  [EBasketStatus.LimitExceeded]: {
    buttonText: 'Лимит превышен',
    tooltip: 'Лимитные требования превышены',
  },
  [EBasketStatus.EnoughProduct]: {
    buttonText: 'Недостаточно товара в наличии',
    tooltip: 'Количество доступного товара уменьшилось. Измените количество или смените поставщика',
    notification: 'Количество доступного товара уменьшилось. Измените количество или смените поставщика',
  },
  [EBasketStatus.MinQuantity]: {
    buttonText: 'Условия заказа не выполнены',
    tooltip: 'Условия поставки одного или нескольких&nbspпоставщиков товара в вашей корзине не выполнены',
    notification: 'Условия поставки одного или нескольких поставщиков товара в вашей корзине не выполнены',
  },
  [EBasketStatus.StepQuantity]: {
    buttonText: 'Условия заказа не выполнены',
    tooltip: 'Условия поставки одного или нескольких поставщиков товара в вашей корзине не выполнены',
    notification: 'Условия поставки одного или нескольких поставщиков товара в вашей корзине не выполнены',
  },
  [EBasketStatus.MinSumNotReached]: {
    buttonText: 'Условия заказа не выполнены',
    tooltip: 'Условия поставки одного или нескольких поставщиков товара в вашей корзине не выполнены',
    notification: 'Условия поставки одного или нескольких поставщиков товара в вашей корзине не выполнены',
  },
  [EBasketStatus.ErpUser]: {
    buttonText: 'Оформить заказ',
    tooltip: 'Товар доступен к заказу только при передаче потребности из ERP',
    notification: 'Товар доступен к заказу только при передаче потребности из ERP',
  },
  [EBasketStatus.NoDelivery]: {
    buttonText: 'Ошибка расчета доставки',
    tooltip: 'Часть товаров из корзины не может быть доставлена. Попробуйте обновить страницу, если проблема повторяется - оформите заказ позднее или удалите товары с ошибками',
  },
  [EBasketStatus.OutOfCount]: {
    buttonText: 'Условия заказа не выполнены',
    tooltip: 'Количество товара превышает значение,\nзаявленное в потребности',
  },
  [EBasketStatus.NotChanged]: {
    buttonText: 'Сохранить заказ',
    tooltip: 'Для сохранения заказа необходимо\nвнести изменение в его состав',
  },
  [EBasketStatus.NoStock]: {
    buttonText: 'Недостаточно товара в наличии',
    tooltip: 'Количество доступного товара уменьшилось.\nИзмените количество или смените поставщика',
  },
  [EBasketStatus.UpdateDataInNeed]: {
    buttonText: 'Обновить данные в потребности',
  },
  [EBasketStatus.MinCost]: {
    buttonText: 'Недостаточная стоимость заказа',
    tooltip: 'Минимальная стоимость заказа — ',
  },
  [EBasketStatus.MaxCost]: {
    buttonText: 'Превышена стоимость заказа',
    tooltip: 'Максимальная стоимость заказа — ',
  },
  [EBasketStatus.NotAcceptedUser]: {
    buttonText: 'Оформление заказа недоступно',
    tooltip: 'Возможность оформления заказа для \nвашего аккаунта временно отключена',
  },
  [EBasketStatus.LimitRequirementsNotMet]: {
    buttonText: 'Условия заказа не выполнены',
    tooltip: 'Лимитные требования по одному или нескольким товарам не выполнены',
  },
  [EBasketStatus.OfferGone]: {
    buttonText: 'Условия заказа не выполнены',
    tooltip: 'Условия поставки одного или нескольких поставщиков<br>товара в вашей корзине не выполнены',
    notification: 'Условия поставки одного или нескольких поставщиков<br>товара в вашей корзине не выполнены',
  },
};

export const basketLoaderMap = new Map<EBasketActionLoaderType, IBasketLoader>([
  [EBasketActionLoaderType.Save, { text: 'Идет оформление заказа...', caption: 'Пожалуйста, дождитесь окончания оформления', withBackground: true }],
  [EBasketActionLoaderType.Delete, { text: 'Удаляем позицию из корзины...', caption: 'Дождитесь завершения процесса', withBackground: true }],
  [EBasketActionLoaderType.Empty, { text: '', caption: '', withBackground: false }],
  [EBasketActionLoaderType.ClearErp, { text: 'Идет удаление заказа...', caption: 'По окончанию процесса очистки,<br>вы вернетесь к списку заказов', withBackground: true }],
  [EBasketActionLoaderType.Restore, { text: 'Восстанавливаем позицию в корзину...', caption: 'Дождитесь завершения процесса', withBackground: true }],
  [EBasketActionLoaderType.Redistribute, { text: 'Идет подбор товаров...', caption: 'Дождитесь завершения процесса, это может занять несколько минут', withBackground: true }],
  [EBasketActionLoaderType.BasketDistribution, { text: 'Идет подбор оптимальных предложений...', caption: 'Пожалуйста, дождитесь окончания процесса. Это может занять несколько минут.', withBackground: true }],
  [EBasketActionLoaderType.FormingReport, { text: 'Идет формирование отчета...', caption: 'Дождитесь завершения процесса', withBackground: true }],
]);
