import { ProviderService } from '../models/providerService.model';
import { FilterUtils } from '../utils/filterHelper.util';
import { IFilterItem, IFiltersMakeQueryParams } from '../models/filters.model';
import { ESortState } from '../enums/sortState.enum';
import { ITableSort } from '../models/table.model';
import { NavigationFailure } from 'vue-router';

export interface IQueryParams {
  search?: string;
  sort?: ITableSort | string;
  page?: string | number;
  filter?: IFilterItem[] | undefined;
  categoryId?: string | number;
}

export default class QueryParamsService extends ProviderService {
  static readonly serviceName = 'queryParamsService';
  route = useRoute();
  router = useRouter();

  private static instance: QueryParamsService;

  private constructor() {
    super();
  }

  public static getInstance(): QueryParamsService {
    if (!QueryParamsService.instance) {
      QueryParamsService.instance = new QueryParamsService();
    }

    return QueryParamsService.instance;
  }

  async saveQueryParams(params: IQueryParams, filtersMakeQueryParams?: IFiltersMakeQueryParams, saveFilterFromRoute?: boolean): Promise<void | NavigationFailure | undefined> {
    return await this.router.replace({
      query: {
        ...this.route.query,
        search: params.search || undefined,
        sort: this.getSort(params.sort),
        page: params.page !== 1 ? params.page || undefined : undefined,
        categoryId: params.categoryId || undefined,
        filter:
          params.filter && !!FilterUtils.getSelectedFilters(params.filter)?.length
            ? FilterUtils.makeQueryObjectString(params.filter, filtersMakeQueryParams)
            : saveFilterFromRoute
              ? this.route.query.filter || undefined
              : undefined,
      },
    });
  }

  async clearQueryParam(nameParams: string): Promise<void> {
    await this.router.replace({
      query: {
        ...this.route.query,
        [nameParams]: undefined,
      },
    });
  }

  async saveQueryParamItem(nameParams: string, value: string): Promise<void> {
    await this.router.replace({
      query: {
        ...this.route.query,
        [nameParams]: value,
      },
    });
  }

  async clear(): Promise<void> {
    await this.router.replace({
      query: {
        ...this.route.query,
        search: undefined,
        sort: undefined,
        page: undefined,
        filter: undefined,
        categoryId: undefined,
      },
    });
  }

  getQueryParams(): IQueryParams {
    this.route = useRoute();
    const result: IQueryParams = {};

    if (this.route.query.page) {
      result.page = Number(this.route.query.page);
    }

    if (this.route.query.sort) {
      const tempSort = (this.route.query.sort as string).split('-');
      result.sort = tempSort.length
        ? {
          field: tempSort[0],
          order: tempSort[1] as ESortState,
        }
        : undefined;
    }

    if (this.route.query.search) {
      result.search = this.route.query.search as string;
    }

    if (this.route.query.categoryId) {
      result.categoryId = this.route.query.categoryId as string;
    }

    return result;
  }

  getOriginalFiltersQueryParams(): Record<string, string | null | Array<string | null>> {
    this.route = useRoute();
    return {
      search: this.route.query.search,
      sort: this.route.query.sort,
      page: this.route.query.page,
      filter: this.route.query.filter,
      categoryId: this.route.query.categoryId,
    };
  }

  getFilterFromQueryParams(filters: IFilterItem[], tempQueryFilter?: string): IFilterItem[] {
    const queryFilters = tempQueryFilter || this?.route?.query?.filter;
    if (!queryFilters) {
      return filters;
    }

    return FilterUtils.setFilterValuesFromQuery(filters, queryFilters);
  }

  getFieldValueFromQueryParams(field: string): string {
    return this.route?.query?.filter && JSON.parse(this.route?.query?.filter as string)[field];
  }

  private getSort(sort: ITableSort | string | undefined): string | undefined {
    if (typeof sort !== 'string' && (sort?.field == null || sort?.order == null)) {
      return;
    }

    return typeof sort === 'string' ? sort : sort.field + '-' + sort.order;
  }
}
