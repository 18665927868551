import { ProviderService } from 'shared/models/providerService.model';
import {
  IAddProduct,
  IAddProductResponse,
  IBasket,
  IBasketCountInfo,
  IBasketCreateBody,
  IBasketInfo,
  IMpBasketPlaceBody,
  ISaveBasketResponse,
} from 'models/basket.model';
import { BasketApi } from 'services/api/basketApi.service';
import { useUserStore } from 'store/user.store';
import { createAsyncLoader } from 'shared/utils/asyncLoader.util';
import { BasketCounter } from './basketCounter.service';
import { CARD_NOT_FOUND_ERROR, INVALID_CART_ERROR } from 'constants/error.const';
import { clientSentry } from 'shared/utils/sentry/clientSentry.util';
import Notificator from 'shared/services/notificator.service';
import Loader from 'shared/utils/loaderHelper.util';

export class BasketManager extends ProviderService {
  protected static readonly serviceName = 'basketManagerService';

  private userStore = useUserStore();
  private static instance: BasketManager | undefined;

  public basketId = ref<number>();
  public selectedBaseId = ref<number>();

  public loadingUserBaskets = Loader.getReactiveInstance();

  private basketCounter = new BasketCounter();
  private userId = ref<string>('');
  private userBaskets: Array<IBasketInfo> | undefined;
  private subdivisionId = ref<number>();

  private loading: Promise<unknown> | undefined;

  constructor() {
    if (BasketManager.instance) {
      return BasketManager.instance;
    }

    super();
    BasketManager.instance = this;
  }

  private resetBasket(): void {
    this.basketCounter.setCount(0);
  }

  public getCurrentBasket() {
    return this.userBaskets?.find((basket) => basket.id === this.basketId.value);
  }

  async handleRequest<T>(makeRequest: () => T) {
    if (!this.basketId.value) {
      this.loading ? await this.loading : await this.refreshBasketData();
    }
    return makeRequest();
  }

  public async refreshBasketData(forceFetch?: boolean) {
    if (!this.userStore?.userId) {
      return;
    }

    if (this.loading) {
      return await this.loading;
    }

    const { loading, finishLoading } = createAsyncLoader();

    try {
      this.loading = loading;
      this.userId.value = this.userStore.userId;
      this.selectedBaseId.value = this.userStore.selectedBase?.id as number;

      if (!this.userBaskets) {
        await this.refreshUserBaskets();
      }

      const currentBasket = this.userBaskets?.find((basket) => basket.basisId === this.selectedBaseId.value);
      const currentBasketId = currentBasket?.id;

      if (!currentBasketId || forceFetch) {
        const newBasket = await BasketApi.createBasket(this.userId.value, {
          basisId: this.userStore.selectedBase?.id as number,
          clientId: this.userStore.clientId,
          subdivisionId: this.userStore.selectedBase?.subdivisionId,
        });

        this.userBaskets?.push(newBasket);
        this.basketCounter.setCount(newBasket.count, this.selectedBaseId.value);
        this.selectedBaseId.value = newBasket.basisId;
        this.subdivisionId.value = newBasket.subdivisionId;
        this.basketId.value = newBasket?.id;
      } else {
        this.basketCounter.setCount(currentBasket?.count as number);
        this.selectedBaseId.value = currentBasket?.basisId;
        this.subdivisionId.value = currentBasket?.subdivisionId;
        this.basketId.value = currentBasketId;
      }
    } finally {
      finishLoading?.();
      this.loading = undefined;
    }
  }

  public async findUserBasketByBasis(basisId: number, subdivisionId?: number): Promise<IBasketInfo | undefined> {
    try {
      await this.refreshUserBaskets();
      return (this.userBaskets || []).find(
        (userBasket) => userBasket.basisId === basisId && (
          !subdivisionId || subdivisionId === userBasket.subdivisionId
        ),
      );
    } catch (error) {
      clientSentry.captureServiceException(
        error,
        BasketManager.getServiceName(),
        undefined,
        {
          extra: {
            basisId,
            subdivisionId,
            userId: this.userId,
          },
        },
      );
    }
  }

  public async getUserBaskets(): Promise<Array<IBasketInfo>> {
    return this.handleRequest(() => BasketApi.getUserBaskets(this.userId.value));
  }

  public async createBasket(body: IBasketCreateBody): Promise<IBasketInfo> {
    return this.handleRequest(() => BasketApi.createBasket(this.userId.value, body));
  }

  public async getBasket(): Promise<IBasket> {
    return await this.handleRequest(() => BasketApi.getBasket(this.basketId.value as number));
  }

  public async clearBasket(): Promise<IBasket> {
    return this.handleRequest(async () => {
      this.resetBasket();
      return await BasketApi.clearBasket(this.basketId.value as number);
    });
  }

  public async addProduct(data: IAddProduct): Promise<IAddProductResponse> {
    try {
      return await this.handleRequest(() => BasketApi.addProduct(this.basketId.value as number, data));
    } catch (error) {
      if ([INVALID_CART_ERROR, CARD_NOT_FOUND_ERROR]?.includes(error?.data?.data?.src)) {
        await this.refreshBasketData(true);
        return await BasketApi.addProduct(this.basketId.value as number, data);
      }

      throw error;
    }
  }

  public async deleteProduct(itemId: number): Promise<IBasket> {
    return await this.handleRequest(() => BasketApi.deleteProduct(itemId));
  }

  public async updateProductCount(itemId: number, quantity: number): Promise<IBasket> {
    return await this.handleRequest(() => BasketApi.updateProductCount(itemId, quantity));
  }

  public async placeOrder(payload: IMpBasketPlaceBody): Promise<ISaveBasketResponse> {
    return await this.handleRequest(async () => {
      const response = await BasketApi.placeOrder(this.basketId.value as number, payload);
      await this.removeCurrentBasket();
      return response;
    });
  }

  private async removeCurrentBasket() {
    this.userBaskets = this.userBaskets?.filter((basket) => basket.id !== this.basketId.value);
    this.basketId.value = undefined;
    this.refreshBasketData();
  }

  public async getCounterAndBasisId(): Promise<IBasketCountInfo> {
    if (this.loading) {
      await this.loading;
    }
    return {
      count: this.basketCounter.counter.value as number,
      basisId: this.selectedBaseId?.value as number,
      subdivisionId: this.subdivisionId?.value as number,
    };
  }

  public async getBasketReportById() {
    return this.handleRequest(() => BasketApi.getBasketReportById(this.basketId.value as number));
  }

  public async getCartDistributionStatus() {
    return this.handleRequest(() => BasketApi.getCartDistributionStatus(this.basketId.value as number));
  }

  public async downloadSpecification() {
    return this.handleRequest(() => BasketApi.downloadSpecification(this.basketId.value as number));
  }

  private async refreshUserBaskets(): Promise<void> {
    if (this.loadingUserBaskets.value) {
      return;
    }

    this.loadingUserBaskets.activate();
    try {
      this.userBaskets = await BasketApi.getUserBaskets(this.userId.value);
    } catch (error) {
      clientSentry.captureServiceException(
        error,
        BasketManager.getServiceName(),
        undefined,
        {
          extra: {
            userId: this.userId.value,
          },
        },
      );
      Notificator.showDetachedNotification('Ошибка загрузки корзин пользователя');
    } finally {
      this.loadingUserBaskets.deactivate();
    }
  }
}
