<template>
  <div
    :key="updateVersion"
    ref="selectWrapperRef"
    class="mm-select"
    :class="{
      'mm-select--label': label,
      'mm-select--is-invalid': isInvalid,
      'mm-select--dirty': isDirty,
      'mm-select--disabled': disabled,
      'mm-select--required': required,
      'mm-select--no-indicator-rotation': disableIndicatorRotation,
    }"
  >
    <div class="mm-select__wrapper">
      <VSelect
        ref="multiSelectRef"
        :dropdown-should-open="dropdownShouldOpen"
        v-bind="{ ...$attrs, ...$props }"
        class="mm-select--size"
        :class="{
          'mm-select--size-little': size === ESelectSizeType.Little,
          'mm-select--size-medium': size === ESelectSizeType.Medium,
          'mm-select--dark-theme': isDarkTheme,
          'footer--visible': footer,
        }"
        :model-value="modelValue"
        :clearable="isClearable || (!hideClear && !disabled)"
        :disabled="disabled"
        :label="labelField"
        :reduce="internalReduce"
        :filter-by="filterByFn"
        :append-to-body="isTopPlacement || isAppendToBody"
        :calculate-position="withPopper"
        :clear-search-on-select="!useSearchText && clearSearchOnSelect"
        @click="onContainerClick"
        @search:focus="onSearchFocus"
        @update:model-value="onSelect"
        @search="onSearch"
        @close="onClose"
        @open="onOpen"
        @search:compositionstart="$emit('search:compositionstart', $event)"
        @search:compositionend="$emit('search:compositionend', $event)"
        @search:keydown="$emit('search:keydown', $event)"
        @search:blur="onBlur"
        @search:input="$emit('search:input', $event)"
        @option:created="$emit('option:created', $event)"
        @option:selecting="$emit('option:selecting', $event)"
        @option:selected="$emit('option:selected', $event)"
        @option:deselecting="$emit('option:deselecting', $event)"
        @option:deselected="$emit('option:deselected', $event)"
      >
        <template
          v-for="(_, name) in $slots"
          #[name]="slotData"
          :key="name"
        >
          <ClientOnly>
            <slot
              :name="name"
              v-bind="slotData"
            />
          </ClientOnly>
        </template>

        <template #open-indicator="{ attributes }">
          <div
            v-show="isCopyIconVisible"
            class="model__copy-icon-wrapper"
            @click="onCopyFieldValue"
          >
            <SvgIcon
              :src="props.copyIcon"
              class="model__copy-icon"
              v-bind="attributes"
            />
          </div>

          <SvgIcon
            v-if="!hideIndicatorIcon"
            :src="indicatorIconSrc"
            v-bind="attributes"
          />
        </template>

        <template #option="option">
          <template v-if="[ESelectType.SimpleList, ESelectType.DescriptionList].includes(type)">
            <div
              class="select-option"
              :class="{
                'custom-option': isCustomOption,
                'disabled-option' : disableOptionFn?.(option),
                customOptionClass
              }"
            >
              <div>
                <div
                  class="select-option__label"
                  :class="{ 'font-500': ESelectType.DescriptionList === type }"
                >
                  {{ getOptionLabel(option) }}
                  <span
                    v-if="option.inRowDescription"
                    class="select-option__description description-in-row"
                    :class="{
                      'select-option__description--same-size': props.isInRowDescriptionSameSize
                    }"
                  >{{ option.inRowDescription }}
                  </span>
                </div>

                <div
                  v-if="ESelectType.DescriptionList === type"
                  class="select-option__description"
                >
                  <span v-html="sanitize(option.description)" />
                </div>
              </div>

              <SvgIcon
                v-if="isSelected(option)"
                src="indicators/checkmark-simple"
                class="select-option--selected__icon"
              />
            </div>
            <hr v-if="!isOptionSeparatorHidden">
          </template>

          <template v-if="type === ESelectType.Checkbox">
            <Checkbox
              class="select-checkbox"
              :class="customOptionClass"
              :disabled="disableOptionFn?.(option)"
              :model-value="isSelected(option)"
            >
              {{ getOptionLabel(option) }}
            </Checkbox>
          </template>
        </template>

        <template #selected-option-container="{ option }">
          <span class="vs__selected">
            {{ getOptionLabel(option, true) }}
          </span>
        </template>

        <template
          v-if="footer || lazy"
          #list-footer
        >
          <div
            v-if="footer"
            class="select-list__footer"
          >
            <button
              v-if="footerCancelText"
              class="btn btn-secondary"
              @click="onClickCancel"
            >
              {{ footerCancelText }}
            </button>
            <button
              v-if="footerOkText"
              class="btn btn-primary"
              @click="onClickSelect"
            >
              {{ footerOkText }}
            </button>
          </div>

          <div
            v-show="hasLazyLoad"
            ref="lazyLoadRef"
            class="footer-load"
          >
            <ClipLoader v-if="props.loadingLabel" />
            {{ props.loadingLabel }}
          </div>
        </template>

        <template #no-options="{ search, searching }">
          <div
            v-if="searching"
            v-html="sanitize(searchingTextFn(search))"
          />

          <div
            v-else
            :class="props.isItalics ? 'mm-select-italic' : 'mm-select-no-italic'"
            :style="{ 'opacity': props.isHideNoResultsLabel ? 0 : 0.5 }"
          >
            {{ props.noResultsLabel }}
          </div>
        </template>

        <template
          v-if="!hideClear && !disabled && isDirty"
          #spinner
        >
          <SvgIcon
            :src="EIconPath.NavigationClose20PxSvg"
            class="mm-select__deselect"
            @click.stop="onClear"
          />
        </template>
      </VSelect>

      <label
        v-if="label"
        class="mm-select__label"
      >
        <span class="mm-select__label-text">
          {{ label }}
        </span>
        <span
          v-if="required"
          class="mm-select__label-icon"
        >
          *
        </span>
      </label>
    </div>

    <div
      v-show="isInvalid"
      class="mm-select__error-container"
    >
      {{ validationField?.errorMessage.value }}
    </div>
  </div>
</template>

<script lang="ts">
const initialType = ESelectType.SimpleList;
export default {};
</script>

<script lang="ts" setup>
interface _VTI_TYPE_ISelectOption {
  [key: string]: unknown;
}
type _VTI_TYPE_ESelectSizeType = /* enum */ number
type _VTI_TYPE_ESelectType = /* enum */ number
type _VTI_TYPE_SelectValue = _VTI_TYPE_ISelectOption | null | string | number | boolean
interface ISelectProps {
  modelValue?: _VTI_TYPE_SelectValue | _VTI_TYPE_Array;
  options: _VTI_TYPE_Array; // Список опций в dropdown
  footer?: boolean; // Отображение футера с кнопками выбора и отмены значений
  type?: _VTI_TYPE_ESelectType; // Тип компонента select
  isDarkTheme?: boolean;
  isOptionSeparatorHidden?: boolean;
  validationField?: _VTI_TYPE_FieldContext;
  label?: string;
  labelField?: string;
  valueField?: string;
  isOnlyValueField?: boolean;
  disabled?: boolean;
  isHideNoResultsLabel?: boolean;
  noResultsLabel?: string;
  hideClear?: boolean;
  reduce?: (value: unknown) => void;
  required?: boolean;
  deselectFromDropdown?: boolean;
  searchBy?: string;
  isTopPlacement?: boolean;
  isClearable?: boolean;
  showCopyIcon?: boolean;
  copyIcon?: string;
  indicatorIconSrc?: string;
  hideIndicatorIcon?: boolean;
  disableIndicatorRotation?: boolean;
  isAppendToBody?: boolean;
  separatorMultiple?: string;
  footerCancelText?: string;
  footerOkText?: string;
  isCustomOption?: boolean;
  lazy?: boolean;
  dataCount?: number;
  customCalculatePosition?: (dropdownList: HTMLUListElement, component: VueSelectInstance, position: ICalculatedPosition) => void;
  isHasLazyLoad?: boolean;
  loadingLabel?: string;
  searchValue?: string;
  searchable?: boolean;
  clearSearchOnSelect?: boolean;
  useSearchText?: boolean;
  searchingTextFn?: (search?: string) => string;
  filterFn?: (option: ISelectOption, search: string) => boolean; // кастомная функция поиска
  displayedLabelField?: string;
  size?: _VTI_TYPE_ESelectSizeType;
  disableOptionFn?: (option: ISelectOption) => boolean; // кастомная функция дисейбла опции по условию
  closeOnTextClick?: boolean; // закрытие при повторном клике на селект
  customOptionClass?: string;
  shouldDisplaySubLabel?: boolean;
  isInRowDescriptionSameSize?: boolean;
  isItalics?: boolean;
}
import { sanitize } from '../directives/sanitize'
import VSelect from 'vue-select'
import { VueSelectInstance } from 'vue-select'
import SvgIcon from './SvgIcon.vue'
import { ISelectOption, SelectValue, ICalculatedPosition } from '../models/select.model'
import { ESelectType } from '../enums/select.enum'
import Checkbox from './Checkbox.vue'
import { isEqual } from 'lodash-es'
import { useUpdate } from '../composables/useUpdate'
import { usePerfectScrollbar } from '../composables/usePerfectScrollbar'
import { createPopper } from '@popperjs/core'
import { EIconPath } from '../enums/iconPath.enum'
import { TextCopyHelper } from '../utils/textCopyHelper.util'
import { ESelectSizeType } from '../enums/selectSizeType.enum'
import WindowWidthManager from '../services/windowWidth.service'
import { WatchSubscription } from '../utils/watchSubscription'
import useSSRUnsubscribeWatch from '../composables/useSSRUnsubscribeWatch'
const props = withDefaults(defineProps<ISelectProps>(), {
  // Должна ли быть отчистка выбора
  clearable: false,
  // Нужна ли поисковая строка
  searchable: true,
  // Должно ли поле отчищаться после выбора
  clearSearchOnSelect: true,
  // Используется ли текст поиска в компоненте
  useSearchText: false,
  // Нужен ли футер
  footer: false,
  // Тип селекта
  type: initialType,
  // Поддерживается ли множественный выбор
  multiple: false,
  // Используется ли темная тема
  isDarkTheme: false,
  // Должны ли разделители опций быть скрыты
  isOptionsSeparatorHidden: false,
  // Имя лейбл поля
  labelField: 'label',
  // Значение
  valueField: 'value',
  // Используется ли только поле значения
  isOnlyValueField: false,
  // Отключен ли компонент
  disabled: false,
  // Нужно ли скрывать "нет результатов"
  isHideNoResultsLabel: true,
  // Текст, отображаемый когда результатов поиска нет
  noResultsLabel: 'Введите адрес',
  // Функция, возвращающая текст результата поиска
  searchingTextFn: (search: string) => `Нет результатов для <em>${search}</em>.`,
  // Требуется ли обязательно выбрать значение в компоненте
  required: false,
  // Должна ли быть доступна опция снятия выбора в выпадающем списке
  deselectFromDropdown: true,
  // Расположен ли выпадающий список вверху компонента
  isTopPlacement: false,
  // Должна ли быть скрыта опция отчистки
  hideClear: true,
  // Должна ли опция отчистки быть доступной
  isClearable: false,
  // Флаг отображения иконки
  showCopyIcon: false,
  // Путь к иконке
  copyIcon: 'action/copy',
  // Путь к иконке индикатора
  indicatorIconSrc: 'navigation/arrow-down',
  // Скрывать иконку индикатора
  hideIndicatorIcon: false,
  // Должен ли компонент добавляться в Body
  isAppendToBody: false,
  // Текст кнопки отмены
  footerCancelText: 'Отменить',
  // Текст кнопки принятия
  footerOkText: 'Применить',
  // Должны ли настраиваться опции
  isCustomOption: false,
  // Выбранные значения в компоненте
  modelValue: undefined,
  // Количество данных при загрузке
  dataCount: 0,
  // Текст при загрузке
  loadingLabel: 'Загрузка данных...',
  // Значение текста поиска
  searchValue: '',
  // Размер компонента
  size: ESelectSizeType.Medium,
  // Должна ли строка описания быть того же размера
  isInRowDescriptionSameSize: false,
  // Нужно ли использовать курсив и центрирование (по умолчанию в b2b)
  isItalics: true,
});
// Описание событий см. https://vue-select.org/api/events.html
const emits = defineEmits<{
  (e: 'click', event: PointerEvent): void;
  (e: 'update:modelValue', value: SelectValue);
  (e: 'close');
  (e: 'open');
  (e: 'search', search: string, loading: boolean);
  (e: 'search:compositionstart');
  (e: 'search:compositionend');
  (e: 'search:keydown');
  (e: 'search:blur');
  (e: 'search:focus');
  (e: 'search:input');
  (e: 'option:created');
  (e: 'option:selecting');
  (e: 'option:selected');
  (e: 'option:deselecting');
  (e: 'option:deselected');
  (e: 'clearSelection');
  (e: 'loadMore'): void | Promise<void>;
  (e: 'okButtonClick', click: boolean);
}>();
const { updateVersion, incrementVersion: updateSelect } = useUpdate();
const selectWrapperRef = ref<null | HTMLDivElement>(null);
const multiSelectRef = ref<null | InstanceType<typeof VSelect>>(null);
const opening = ref<boolean>(false);
const isOpen = ref(false);
const perfectScrollbar = usePerfectScrollbar('.vs__dropdown-menu');
const searchText = ref(props.searchValue);
const lazyLoadRef = ref();
const lazyLoadObserver = ref(null);
const isInvalid = computed<boolean>(() => !!props.validationField?.meta.touched && !!props.validationField?.errorMessage.value);
const isCopyIconVisible = computed(() => props.showCopyIcon && props.disabled && props.modelValue);
const hasLazyLoad = computed<boolean>(() => (
    props.lazy && (props.isHasLazyLoad !== undefined ? props.isHasLazyLoad : props.options.length < props.dataCount))
  || false,
);
function onContainerClick(event: PointerEvent): void {
  emits('click', event);
  if (!props.closeOnTextClick) {
    return;
  }
  /*При клике на инпут не происходит блюр, поэтому если кликнули на инпут и селект открыт, но не открывается в данный момент(первоначальное открытие), то блюрим*/
  if (event.target.classList.contains('vs__search') && isOpen.value && !opening.value) {
    blurSearch();
    return;
  }
}
function withPopper(dropdownList: HTMLUListElement, component: VueSelectInstance, position: ICalculatedPosition) {
  const { width, top, left } = position;
  if (props.customCalculatePosition) {
    return props.customCalculatePosition(dropdownList, component, position, selectWrapperRef.value);
  }
  if (!props.isTopPlacement) {
    dropdownList.style.top = top;
    dropdownList.style.left = left;
    dropdownList.style.width = width;
    return;
  }
  dropdownList.style.width = width;
  const popper = createPopper(component.$refs.toggle, dropdownList, {
    placement: 'top',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 3],
        },
      },
    ],
  });
  return () => popper.destroy();
}
function getElementsFromSelect(el: HTMLElement, inputElementClass = '.vs__selected'): Array<Element> {
  const actionsElement = (el as HTMLElement)?.parentElement?.parentElement;
  return Array.from((actionsElement as HTMLElement)?.querySelectorAll(inputElementClass) ?? []);
}
function getFormattedTextFromNodes(nodes: Array<Element>, delimiter = TextCopyHelper.newLineCharacter): string {
  return nodes?.map((node) => node?.textContent).join(delimiter).trim();
}
function onCopyFieldValue(event: MouseEvent): void {
  const text = getFormattedTextFromNodes(getElementsFromSelect(event.currentTarget as HTMLElement));
  if (text) {
    TextCopyHelper.copy(text);
  }
}
function filterByFn(option: ISelectOption, label: string, search: string): boolean {
  if (props.filterFn) {
    return props.filterFn(option, search);
  }
  return !!option[props.searchBy || props.labelField]?.toString().trim().toLocaleLowerCase().includes(search.trim().toLocaleLowerCase());
}
function onSelect(value: SelectValue): void {
  emits('update:modelValue', Array.isArray(value) ? value.filter((val) => val) : value);
  props.useSearchText && setInternalMultiselectSearch(value);
  props.validationField?.validate();
}
function onBlur(): void {
  props.validationField?.setTouched(true);
  props.validationField?.validate();
  emits('search:blur');
  setIsOpen(false);
  setOpenSelect(false);
}
function isSelected(value: ISelectOption): boolean {
  if (Array.isArray(props.modelValue)) {
    return props.modelValue.some((option) => (((option && option[props.valueField]) || option) === ((value && value[props.valueField]) || value)));
  }
  return isEqual((props.modelValue?.[props.valueField] ?? props.modelValue), value[props.valueField] ?? value);
}
const searchOptions = ref<Array<ISelectOption>>(props.options ?? []);
const watchSubscription = new WatchSubscription();
const { isDesktop, isLargeDesktop } = WindowWidthManager.getAdaptivaRefs();
const internalReduce = computed(() => (
  props.reduce || (props.isOnlyValueField ? (value: Record<string, unknown>) => value?.[props.valueField] : undefined)
));
const isDirty = computed<boolean>(() => props.multiple || props.type === ESelectType.Checkbox || Array.isArray(props.modelValue) ? !!props.modelValue?.length : (props.modelValue != null && props.modelValue !== ''));
const addressvisibility = computed<string>(() => !searchOptions.value?.length && props.isHideNoResultsLabel ? 'hidden' : 'visible');
function onClickCancel(): void {
  clearSelection();
  emits('clearSelection');
  setIsOpen(false);
  setOpenSelect(false);
  blurSearch();
}
function clearSelection(): void {
  (multiSelectRef.value as unknown as { clearSelection(): void })?.clearSelection();
}
function setOpenSelect(value: boolean): void {
  if (multiSelectRef.value) {
    multiSelectRef.value.$data.open = value;
  }
}
function setInternalMultiselectSearch(value: SelectValue): void {
  if (multiSelectRef.value) {
    multiSelectRef.value.$data.search = String(value);
  }
}
function setIsOpen(value: boolean): void {
  isOpen.value = value;
}
function onClickSelect(): void {
  setIsOpen(false);
  setOpenSelect(false);
  blurSearch();
  emits('okButtonClick', true);
}
function blurSearch(): void {
  (multiSelectRef.value as unknown as { searchEl: HTMLInputElement }).searchEl.blur();
}
function dropdownShouldOpen({ noDrop, open, mutableLoading }: VueSelectInstance): boolean | undefined {
  return noDrop ? false : open && !mutableLoading;
}
function onSearchFocus(): void {
  setIsOpen(true);
  setOpenSelect(true);
  emits('search:focus');
}
function updateValue(): void {
  multiSelectRef.value?.updateValue();
}
function getOptionLabel(option: ISelectOption, separator = false): string {
  const label = option[props.labelField] as string;
  const separatorMultiple =
    Array.isArray(props.modelValue) && (props.modelValue[props.modelValue.length - 1] === label || props.modelValue[props.modelValue.length - 1]?.label === label)
      ? ''
      : props.modelValue?.length > 1
        ? props.separatorMultiple
        : '';
  if (separator && props.displayedLabelField) {
    return option[props.displayedLabelField] as string;
  }
  return Array.isArray(props.modelValue) && props.separatorMultiple && separator
    ? `${label}${separatorMultiple}`
    : option.inRowDescription && !separator ? label + ',' : label;
}
function updateScrollbar(): void {
  perfectScrollbar.destroy();
  perfectScrollbar.init();
  perfectScrollbar.update();
}
function onClose(): void {
  emits('close');
}
async function initLazyLoadObserverElement(): Promise<void> {
  if (!hasLazyLoad.value) {
    return;
  }
  await nextTick();
  lazyLoadObserver.value?.observe?.(lazyLoadRef.value);
}
function initLazyLoadObserver(): void {
  lazyLoadObserver.value = new IntersectionObserver(async ([{ isIntersecting, target }]) => {
    if (!isIntersecting) {
      return;
    }
    const ul = (target as HTMLLIElement)?.offsetParent;
    if (!ul) {
      return;
    }
    const scrollTop = (target as HTMLLIElement)?.offsetParent?.scrollTop;
    await emits('loadMore');
    await nextTick();
    ul.scrollTop = scrollTop || 0;
    lazyLoadObserver.value?.unobserve?.(target);
    initLazyLoadObserverElement();
  });
}
function initInternalMultiselectSearch(): void {
  props.useSearchText && setInternalMultiselectSearch(props.modelValue);
}
function onOpen(): void {
  opening.value = true;
  setTimeout(() => opening.value = false, 200);
  emits('open');
  perfectScrollbar.init();
  initLazyLoadObserverElement();
}
function onSearch(search: string, loading: boolean): void {
  updateScrollbar();
  emits('search', search, loading);
  searchText.value = search;
}
function onClear(): void {
  setInternalMultiselectSearch('');
  emits('option:deselected', undefined);
  emits('update:modelValue', Array.isArray(props.modelValue) ? [] : undefined);
}
function onChangeSearchText(text: string): void {
  if (!props.useSearchText) {
    return;
  }
  emits('update:modelValue', text);
  props.validationField?.validate();
}
watchSubscription.add(
  watch(
    () => searchText.value,
    (value) => onChangeSearchText(value),
  ),
  watch(
    () => props.options,
    (newValue) => {
      searchOptions.value = newValue;
      updateScrollbar();
      initInternalMultiselectSearch();
    },
    { deep: true },
  ),
  watch(
    () => props.modelValue,
    () => updateScrollbar(),
    { flush: 'post', deep: true },
  ),
  watch(
    () => [isDesktop.value, isLargeDesktop.value],
    () => updateSelect(),
  ),
);
useSSRUnsubscribeWatch(watchSubscription);
onMounted(() => {
  props.validationField?.validate();
  initLazyLoadObserver();
});
onBeforeUnmount(() => {
  perfectScrollbar.destroy();
});
defineExpose({
  updateValue,
  setOpenSelect,
  multiSelectRef,
  setInternalMultiselectSearch,
});
</script>

<style lang="scss" scoped>
@import 'styles/base/common/variables';

$root: '.vs';
$dropdown: '#{$root}__dropdown';
$mm-select: '.mm-select';
$activated-label: translate3d(0, -85%, 0) scale(0.85);

:deep([role="listbox"]) {
  visibility: v-bind(addressvisibility) !important;
}

#{$mm-select} {
  padding-bottom: 12px;

  :deep(#{$root}__clear) {
    display: none;
  }

  &__deselect {
    cursor: pointer;
    margin-right: 5px;
  }

  &--dark-theme {
    &.vs--open {
      :deep(.vs__dropdown-toggle) {
        background-color: $light-gray;
        border-color: $gray-200 !important;
      }
    }
  }

  &--size {
    :deep(.vs__dropdown-toggle) {
      transition: all 0.2s;
      border: 1px solid $select-toggle-border-color;
      border-radius: 8px;
    }

    &-medium {
      :deep(.vs__dropdown-toggle) {
        padding: 16px;
      }
    }

    &-little {
      :deep(.vs__dropdown-toggle) {
        padding: 10px 16px;
      }
    }
  }

  :deep(#{$root}__actions) {
    padding: 0;
  }

  &--label {
    #{$mm-select}__wrapper {
      position: relative;

      #{$mm-select}__label {
        user-select: none;
        pointer-events: none;
        position: absolute;
        color: #657a73;
        font-size: 14px;
        line-height: 20px;
        top: 28px;
        transform: translateY(-50%);
        left: 17px;
        z-index: 1;
        transform-origin: 0 0;
        transition: all 0.2s ease;
        overflow: hidden;
        max-width: calc(100% - 56px);
        display: flex;

        #{$mm-select}__label-text {
          flex: 1;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        #{$mm-select}__label-icon {
          flex: 0;
          color: $required-input-star-c;
          margin-left: 3px;
        }
      }

      #{$root}--open + #{$mm-select}__label,
      #{$root}--searching + #{$mm-select}__label {
        transform: $activated-label;
      }

      ::v-deep(#{$dropdown}-toggle) {
        padding: 24px 16px 8px !important;

        #{$root}__search {
          padding-left: 0;
        }

        #{$root}__actions {
          padding: 0;
          position: absolute;
          top: 50%;
          right: 16px;
          z-index: 2;
          display: flex;
          flex-direction: row-reverse;

          &:has(.mm-select__deselect) {
            top: 35%;
          }
        }
      }

      #{$root}--searching {
        :deep(#{$root}__search) {
          opacity: 1 !important;
        }
      }
    }

    &#{$mm-select}--dirty {
      #{$mm-select}__wrapper {
        #{$mm-select}__label {
          transform: $activated-label;
        }
      }
    }
  }

  :deep(#{&}__error-container) {
    color: $input-error-c;
    font-size: 12px;
    padding-left: 16px;
  }

  &--is-invalid {
    :deep(#{$root}__dropdown-toggle) {
      border-color: $input-error-border !important;
    }
  }

  &--disabled {
    :deep(#{$root}__dropdown-toggle) {
      background-color: $light-gray;
    }

    #{$mm-select}__label {
      color: $text-disabled !important;
    }

    .vs__open-indicator {
      :deep(path) {
        fill: $text-disabled;
      }
    }

    .model__copy-icon-wrapper {
      padding: 5px;
      cursor: pointer;
      transition: transform 0.1s ease-out, opacity 0.3s ease-out;
      z-index: 11;
      opacity: 0;
      position: absolute;
      right: -11px;

      svg {
        cursor: pointer;

        :deep(path) {
          fill: $text-dark-green;
        }
      }

      &:active {
        transform: scale(0.95);
      }
    }

    &__copy-icon {
      width: 20px;
      height: 20px;
      cursor: pointer;
      fill: initial;
    }

    &:hover {
      .model__copy-icon-wrapper {
        opacity: 1;
      }
    }

    :deep(#{$root}__selected) {
      color: $text-disabled !important;
      cursor: initial !important;
    }

    .vs__actions {
      transform: translateY(-50%);

      svg > path {
        fill: $text-disabled !important;
      }
    }

    :deep(#{$root}__search) {
      background-color: transparent;
    }
  }

  &--no-indicator-rotation {
    :deep(.vs__open-indicator) {
      transform: rotate(0) !important;
    }
  }

  :deep(.v-select) {
    #{$dropdown}-menu {
      box-shadow: -2px 2px 16px rgb(3 25 18 / 12%);
      border-radius: 8px;
      margin-top: 4px;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 12px 0;
      border-style: none;
      z-index: 6;

      .ps__rail-y {
        margin: 12px 8px 12px 0;
      }

      #{$dropdown}-option {
        color: $select-option-text-color;
        padding: 0 8px;
        border-radius: 4px;
        margin: 0 8px;
        white-space: normal;

        &:has(.disabled-option) {
          pointer-events: none !important;
        }

        hr {
          margin: 0;
          background-color: $select-option-border-color;
          opacity: 1;
        }

        .select-option {
          padding: 12px 0;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          &.disabled-option {
            color: $text-disabled;
          }

          &--selected {
            &__icon {
              path {
                stroke: $select-option-selected-icon-stroke;
              }
            }
          }
        }

        &:last-of-type > hr {
          background-color: transparent;
        }
      }

      #{$dropdown}-option--highlight {
        background-color: $select-option-background-color;
      }

      .select {
        &-checkbox {
          padding: 6px 0;
        }

        &-option {
          &__label {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            font-style: normal;

            &.font-500 {
              font-weight: 500;
            }
          }

          &__description {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: $select-option-description-text-color;

            .description-in-row {
              white-space: nowrap !important;
            }
          }
        }

        &-list {
          &__footer {
            position: sticky;
            margin-bottom: -12px;
            bottom: -12px;
            left: 0;
            right: 0;
            background-color: $select-menu-footer-background-color;
            height: 84px;
            border-top: 1px solid $select-menu-footer-border-color;
            display: flex;
            align-items: center;
            padding: 20px 20px 24px;
            width: 100%;
            z-index: 6;

            button {
              flex: 1;
              text-align: center;
              display: flex;
              justify-content: center;

              &:first-child {
                margin-right: 4px;
              }

              &:last-child {
                margin-left: 4px;
              }
            }
          }
        }
      }
    }

    #{$dropdown}-toggle[aria-expanded='true'] {
      border-color: $select-toggle-border-focus-color;
    }

    #{$root}-selected {
      color: $select-option-text-color;
    }

    #{$root}__selected-options {
      flex-wrap: nowrap;
      padding: 0;
    }

    #{$root}__selected {
      border: none;
      margin: 0;
      padding: 0;
      position: relative !important;
      opacity: 1 !important;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      color: $select-selected-color;
      margin-right: 4px;
      background-color: transparent;
    }

    #{$root}__search {
      margin: 0;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      color: $select-selected-color;
      padding: 0;

      &::placeholder {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $select-placeholder-color;
      }
    }

    &.vs--multiple {
      .vs__selected-options {
        flex-wrap: wrap;
      }
    }
  }

  :deep(.v-select) {
    .vs__selected-options {
      min-width: 0;

      /* ломает режим use-search-text (задваивается значение при вводе в поиск)
      & > span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
      }*/
    }
  }

  @media only screen and (max-width: 767px) {
    :deep(.v-select) {
      #{$dropdown}-menu {
        padding: 8px 0;
      }
    }
  }
}

.footer-load {
  margin: 0 8px;
  padding: 12px 8px 6px;
  display: flex;

  :deep(.mm-clip-loader) {
    position: static;
    padding-right: 12px;

    .mm-clip-loader__clip {
      width: 20px;
      height: 20px;
    }
  }
}

.v-select.drop-up.vs--open .vs__dropdown-toggle {
  border-radius: 0 0 4px 4px;
  border-top-color: transparent;
}

[data-popper-placement='top'] {
  border-radius: 4px 4px 0 0;
  border-top-style: solid;
  border-bottom-style: none;
}

:global(.vs__dropdown-menu:has(.select-list__footer) .ps__rail-y) {
  margin-bottom: 92px !important;
}

.mm-select-no-italic {
  text-align: left;
  padding: 0 16px;
}

.mm-select-italic {
  font-style: italic;
}
</style>
