import { snakeCase, camelCase } from 'lodash-es';

export class StringsHelper {
  static toPriceString(price: number, options?: Intl.NumberFormatOptions): string {
    const formatOptions = Number.isInteger(price)
      ? options
      : {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        ...options,
      };

    return price?.toLocaleString('ru', formatOptions);
  }

  static capitalizeString(str: string): string {
    return `${str?.[0].toUpperCase()}${str.slice(1)}`;
  }

  static decapitalizeString(str: string): string {
    return `${str?.[0].toLowerCase()}${str.slice(1)}`;
  }

  // преобразование для подписи
  static toBase64ForSign(str: string) {
    const header = ';base64,';
    return str?.slice(str?.indexOf(header) + header.length);
  }

  static toSnake(str: string): string {
    return snakeCase(str);
  }

  static toKebab(str: string) {
    return str?.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
  }

  static camelize(str: string): string {
    return camelCase(str);
  }

  static isNumber(num: string): boolean {
    return !isNaN(num as unknown as number);
  }

  static arrayToString(arr: Array<string | number | undefined | null>, separator = ', '): string {
    return arr?.filter((el) => !!el || typeof el === 'number' && el === 0).join(separator);
  }

  static getLastNameWithInitials(lastName?: string, firstName?: string, patronymic?: string): string {
    if (!lastName && !firstName && !patronymic) {
      return '';
    }

    return `${lastName ?? ''} ${firstName?.[0] ? firstName[0] + '.' : ''}${patronymic?.[0] ? patronymic[0] + '.' : ''}`;
  }

  static extractNumbers(str: string): string {
    return str.replace(/[^0-9]/g, '');
}
}
