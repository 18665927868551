import { useToast } from 'vue-toastification/dist/index.mjs';
import { INotificator } from '../models/notificator.model';
import NotificatorSettings from '../constants/notificator.const';
import { ProviderService } from '../models/providerService.model';
import { ENotificationActions } from '../enums/notificationActions.enum';
import { ECookieKeys } from '../enums/cookieKeys';
import { CookieManager } from '../utils/cookieManager.util';
import RoundSuccessIcon from '../components/icons/RoundSuccessIcon.vue';
import InfoIcon from '../components/icons/InfoNotification.vue';
import NotificationMessage from '../components/NotificationMessage.vue';
import ActionNotification from '../components/ActionNotification.vue';
import CloseIcon from '../components/icons/CloseNotification.vue';
import { ToastID, ToastOptions } from 'vue-toastification/dist/types/types';
import { EQueryParam } from '../enums/queryParam.enum';
import AcceptCookiesNotificationMessage from '../components/AcceptCookiesNotificationMessage.vue';
import {
  INotificatorServiceDetachedNotificationOptions,
  INotificatorServiceLastNotificationOptions,
  INotificatorServiceNotificationOptions,
} from '../models/services/notificatorService.model';
import { ToastInterface, TYPE } from 'vue-toastification';
import WindowWidthManager from 'shared/services/windowWidth.service';
import { PAGES_NAMES_WITH_CUSTOM_NOTIFICATION_POSITION } from '~/constants/acceptCookies.const';
import { WatchStopHandle } from 'vue';
import { EDetachedNotificaionEnum } from '../enums/detachedNotificationIcon.enum';
import SuccessIcon from '../components/icons/SuccessIcon.vue';

export default class Notificator extends ProviderService implements INotificator {
  private toastInstance?: ToastInterface;
  static readonly serviceName = 'notificator';

  public notificatorAction = ref<ENotificationActions | undefined>();

  private static instance: Notificator;

  private lastNotificationOptions = ref<INotificatorServiceLastNotificationOptions | null>(null);
  private isLargeDesktop = WindowWidthManager.getAdaptivaRefs().isLargeDesktop;
  private isDesktop = WindowWidthManager.getAdaptivaRefs().isDesktop;
  private isTablet = WindowWidthManager.getAdaptivaRefs().isTablet;
  private isMobile = WindowWidthManager.getAdaptivaRefs().isMobile;
  private isSmallMobile = WindowWidthManager.getAdaptivaRefs().isSmallMobile;

  private static detachecdIconMap = new Map<EDetachedNotificaionEnum, Component>([
    [EDetachedNotificaionEnum.Info, InfoIcon],
    [EDetachedNotificaionEnum.Succes, SuccessIcon],
    [EDetachedNotificaionEnum.RoundSuccess, RoundSuccessIcon],
  ]);

  private watchAdaptivesAndRoute?: WatchStopHandle;

  constructor(
    private readonly route = useRoute(),
  ) {
    if (Notificator.instance) {
      return Notificator.instance;
    }

    super();
    this.toastInstance = useToast();
    this.applyInvokingNotification();
    this.initWatchAdaptives();

    Notificator.instance = this;
  }

  public static getInstance(): Notificator {
    return new Notificator();
  }

  public getToastInstance(): ToastInterface | undefined {
    return this.toastInstance;
  }

  static showDetachedNotification(message: string, options: INotificatorServiceDetachedNotificationOptions = {}) {
    if (process.server) {
      return;
    }

    const notificator = Notificator.getInstance();
    notificator.clearAllNotifications();

    notificator.lastNotificationOptions.value = options;
    notificator.lastNotificationOptions.value.content = message;

    if (notificator.lastNotificationOptions.value.toastOptions) {
      notificator.lastNotificationOptions.value.toastOptions.type ??= TYPE.INFO;
    } else {
      notificator.lastNotificationOptions.value.toastOptions = { type: TYPE.INFO };
    }

    if (notificator.lastNotificationOptions.value.detachedNotificaion != undefined) {
      notificator.lastNotificationOptions.value.icon = Notificator.detachecdIconMap.get(
        notificator.lastNotificationOptions.value.detachedNotificaion,
      );
    }

    notificator.lastNotificationOptions.value.toastId = notificator
      .getToastInstance()
      ?.(message, notificator.getToastOptions(notificator.lastNotificationOptions.value));
  }

  showNotification(message: string, settings?: Record<string, unknown>, options?: INotificatorServiceNotificationOptions): void {
    // 23.05.22: библиотека еще не поддерживает SSR и выдает ворнинг, рендерится после гидрации
    if (process.server) {
      return;
    }

    this.lastNotificationOptions.value = options || {};
    this.lastNotificationOptions.value.content = message;

    if (this.lastNotificationOptions.value.toastOptions) {
      this.lastNotificationOptions.value.toastOptions.type ??= TYPE.INFO;
    } else {
      this.lastNotificationOptions.value.toastOptions = { type: TYPE.INFO };
    }

    this.lastNotificationOptions.value.toastId = this.toastInstance?.(
      message,
      this.getToastOptions({
        ...(this.lastNotificationOptions.value || {}),
        ...(settings || {})},
      ),
    );
  }

  showActionNotification(
    message: string,
    actionText: string,
    settings: Record<string, unknown> = {},
    cancelFunction: () => void,
  ): ToastID | undefined {
    if (process.server) {
      return;
    }


    this.lastNotificationOptions.value = {
      content: {
        component: ActionNotification,
        props: {
          message,
          actionText,
        },
        listeners: {
          action: () => {
            cancelFunction();
          },
        },
      },
      toastOptions: {
        type: TYPE.INFO,
        ...(settings || {}),
      },
    };

    this.lastNotificationOptions.value.toastId = this.toastInstance?.(
      this.lastNotificationOptions.value.content,
      this.getToastOptions(this.lastNotificationOptions.value),
    );

    return this.lastNotificationOptions.value.toastId;
  }

  showUnauthorizedNotification(): void {
    if (process.server) {
      return;
    }

    const toastContent = this.getToastContent();
    this.lastNotificationOptions.value = {
      content: toastContent.content,
      toastOptions: {
        ...toastContent.options,
        type: TYPE.INFO,
      },
    };

    this.lastNotificationOptions.value.toastId = this.toastInstance?.(
      this.lastNotificationOptions.value.content,
      this.getToastOptions(this.lastNotificationOptions.value),
    );
  }

  showUnauthorizedBasketNotification(): void {
    if (process.server) {
      return;
    }

    const toastContent = this.getToastContent();
    this.lastNotificationOptions.value = {
      content: toastContent.content,
      toastOptions: {
        ...toastContent.options,
        type: TYPE.WARNING,
      },
    };

    this.lastNotificationOptions.value.toastId = this.toastInstance?.(
      this.lastNotificationOptions.value.content,
      this.getToastOptions(this.lastNotificationOptions.value),
    );
  }

  showSuccessfulVerifiedNotification(): void {
    if (process.server) {
      return;
    }

    const toastContent = this.getToastContent();
    this.lastNotificationOptions.value = {
      content: toastContent.content,
      toastOptions: {
        ...toastContent.options,
        type: TYPE.WARNING,
      },
    };

    this.lastNotificationOptions.value.toastId = this.toastInstance?.(
      this.lastNotificationOptions.value.content,
      this.getToastOptions(this.lastNotificationOptions.value),
    );
  }

  public showAcceptCookiesNotification(options?: INotificatorServiceNotificationOptions): void {
    if (process.server) {
      return;
    }

    this.clearAllNotifications();
    this.lastNotificationOptions.value = {
      content: AcceptCookiesNotificationMessage,
      ...(options || {}),
      toastOptions: {
        ...NotificatorSettings.DEFAULT_NOTIFICATION_SETTINGS,
        timeout: false,
        icon: InfoIcon,
        closeButton: false,
        toastClassName: ['mm-notification__toast', 'mm-notification-accept-cookies-message__toast'],
        type: TYPE.INFO,
        ...(options?.toastOptions || {}),
      },
    };

    this.lastNotificationOptions.value.toastId = this.toastInstance?.(
      this.lastNotificationOptions.value.content,
      this.getToastOptions(this.lastNotificationOptions.value),
    );
  }

  private getToastContent() {
    return {
      content: {
        component: NotificationMessage,
        props: {
          message: NotificatorSettings.getMessageByAction(this.notificatorAction.value),
          linkMessage: NotificatorSettings.getLinkMessageByAction(this.notificatorAction.value),
          queryParams: { [EQueryParam.Auth]: 'true' },
        },

      },
      options: {
        ...NotificatorSettings.DEFAULT_NOTIFICATION_SETTINGS,
        icon: InfoIcon,
        closeButton: CloseIcon,
        onClose: () => this.notificatorAction.value = null,
      },
    };
  }

  setUnauthorizedAction(type: ENotificationActions): void {
    this.notificatorAction.value = type;
  }

  setVerifiedAction(): void {
    this.notificatorAction.value = ENotificationActions.SuccessfulVerified;
  }

  setLinkExpiredAction(): void {
    this.notificatorAction.value = ENotificationActions.VerificationLinkExpired;
  }

  public clearAllNotifications(): void {
    this.lastNotificationOptions.value = null;
    this.toastInstance?.clear();
  }

  private applyInvokingNotification(): void {
    if (process.server){
      return;
    }

    watch(this.notificatorAction,
      () => {
        switch (this.notificatorAction.value) {
          case ENotificationActions.UnauthorizedBasket: {
            return this.showUnauthorizedBasketNotification();
          }
          case ENotificationActions.UnauthorizedProposal: {
            return this.showUnauthorizedNotification();
          }
          case ENotificationActions.SuccessfulVerified: {
            this.showSuccessfulVerifiedNotification();
            CookieManager.setCookieObject(ECookieKeys.RedirectAction, null);
            return;
          }
          case ENotificationActions.VerificationLinkExpired: {
            this.showNotification('Ссылка устарела.');
            CookieManager.setCookieObject(ECookieKeys.RedirectAction, null);
            CookieManager.setCookieObject(ECookieKeys.RedirectError, null);
            return;
          }
          case ENotificationActions.ActionCanceled: {
            this.toastInstance?.clear();
            this.notificatorAction.value = null;
            this.showNotification(
              'Действие отменено', {
                ...NotificatorSettings.CONTENT_FIT_NOTIFICATION_SETTINGS,
                icon: RoundSuccessIcon,
              },
            );
            return;
          }
          default:
            return;
        }
      });
  }

  public updateLastNotification(options?: Omit<INotificatorServiceLastNotificationOptions, 'toastId' | 'content'>): void {
    this.lastNotificationOptions.value = {
      ...this.lastNotificationOptions.value,
      ...(options || {}),
    };

    if (typeof this.lastNotificationOptions.value?.toastId !== 'number'
      || this.lastNotificationOptions.value.disabledWatchAdaptives
    ) {
      return;
    }

    this.toastInstance?.update(this.lastNotificationOptions.value.toastId, {
      content: this.lastNotificationOptions.value.content,
      options: this.getToastOptions(this.lastNotificationOptions.value),
    });
  }

  private parseClasses(classes?: string | Array<string>): Array<string> {
    if (!classes) {
      return [];
    }

    return Array.isArray(classes) ? classes : [classes];
  }

  private getToastOptions(options: INotificatorServiceLastNotificationOptions): ToastOptions {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const currentInstance = this;
    return {
      ...NotificatorSettings.DEFAULT_NOTIFICATION_SETTINGS,
      icon: options.icon || InfoIcon,
      closeButton: CloseIcon,
      ...(options.toastOptions || {}),
      onClose() {
        if (options.toastOptions?.onClose) {
          options.toastOptions.onClose();
        }

        currentInstance.clearLastNotification(false);
      },
      toastClassName: [
        NotificatorSettings.DEFAULT_NOTIFICATION_SETTINGS.toastClassName, 
        ...this.parseClasses(options.toastOptions?.toastClassName),
        ...this.parseClasses(this.getToastClassNameByAdaptives(options)), 
      ],
    };
  }

  private getToastClassNameByAdaptives(options: INotificatorServiceNotificationOptions): Array<string> | string | undefined {
    const defaultClassName = NotificatorSettings.DEFAULT_NOTIFICATION_SETTINGS.toastClassName;
    const classes: Array<string> = [defaultClassName];

    if (this.isLargeDesktop.value) {
      classes.push(`${defaultClassName}--large-desktop`);
    }

    if (this.isDesktop.value) {
      classes.push(`${defaultClassName}--desktop`);
    }

    if (this.isTablet.value) {
      classes.push(`${defaultClassName}--tablet`);
    }

    if (this.isMobile.value) {
      classes.push(`${defaultClassName}--mobile`);
    }

    if (this.isSmallMobile.value) {
      classes.push(`${defaultClassName}--small--mobile`);
    }

    if (options.withActionNavbar
      ?? PAGES_NAMES_WITH_CUSTOM_NOTIFICATION_POSITION.includes(this.route.name?.toString() || '')
    ) {
      classes.push(options.actionNavbarClass || `${defaultClassName}--actions-navbar`);
    }

    return classes;
  }

  private clearLastNotification(clearNotifications = true): void {
    if (!this.lastNotificationOptions.value) {
      return;
    }

    this.lastNotificationOptions.value = null;
    clearNotifications && this.toastInstance?.clear();
  }

  private initWatchAdaptives(): void {
    this.watchAdaptivesAndRoute = watch(
      () => [
        this.isLargeDesktop.value,
        this.isDesktop.value,
        this.isTablet.value,
        this.isMobile.value,
        this.isSmallMobile.value,
        this.route.name,
      ],
      () => this.updateLastNotification(),
      { flush: 'post' },
    );
  }
}
