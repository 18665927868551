import {
  IAddProduct,
  IAddProductResponse,
  IBasket,
  IBasketCreateBody,
  IBasketInfo,
  IMpBasketPlaceBody,
  ISaveBasketResponse,
  IBasketDistributionResponse,
} from 'models/basket.model';
import { AuthHelper } from 'utils/authHelper.util';
import { IReport } from 'models/client/report.model';
import FilesService from './files.service';
import { AxiosResponse } from 'axios';

export class BasketApi {
  public static getUserBaskets(userId: string): Promise<Array<IBasketInfo>> {
    return AuthHelper.fetch<Array<IBasketInfo>>(`/api/v2/carts/mp/${userId}`, {
      method: 'GET',
    });
  }

  public static createBasket(userId: string, body: IBasketCreateBody): Promise<IBasketInfo> {
    return AuthHelper.fetch<IBasketInfo>(`/api/v2/carts/mp/${userId}`, {
      method: 'POST',
      body,
    });
  }

  public static getBasket(id: number): Promise<IBasket> {
    return AuthHelper.fetch<IBasket>(`/api/v2/carts/mp/basket/${id}`, {
      method: 'GET',
    });
  }

  public static async clearBasket(id: number): Promise<IBasket> {
    return await AuthHelper.fetch<IBasket>(`/api/v2/carts/mp/basket/${id}`, {
      method: 'DELETE',
    });
  }

  public static async addProduct(id: number, data: IAddProduct): Promise<IAddProductResponse> {
    return await AuthHelper.fetch<IAddProductResponse>(`/api/v2/carts/mp/basket/${id}/items`, {
      method: 'POST',
      body: data,
    });
  }
  public static async deleteProduct(itemId: number): Promise<IBasket> {
    return await AuthHelper.fetch<IBasket>(`/api/v2/carts/mp/items/${itemId}`, {
      method: 'DELETE',
    });
  }

  public static async updateProductCount(itemId: number, quantity: number): Promise<IBasket> {
    return await AuthHelper.fetch<IBasket>(`/api/v2/carts/mp/items/${itemId}`, {
      method: 'PATCH',
      body: {
        quantity,
      },
    });
  }

  public static async placeOrder(id: number, payload: IMpBasketPlaceBody): Promise<ISaveBasketResponse> {
    return await AuthHelper.fetch<ISaveBasketResponse>(`/api/v2/carts/mp/basket/${id}/place`, {
      method: 'POST',
      body: payload,
    });
  }

  public static async getBasketReportById(basketId: number): Promise<IReport> {
    return await AuthHelper.fetch(`/api/v1/orders/${basketId}/report`);
  }

  public static async downloadSpecification(basketId: number): Promise<AxiosResponse<Blob>> {
    return await FilesService.fileDownload<AxiosResponse<Blob>>(
      '',
      {
        method: 'get',
      },
      `/api/v2/carts/download/${basketId}/`,
    );
  }

  public static async getCartDistributionStatus(cartId: number): Promise<IBasketDistributionResponse> {
    return await AuthHelper.fetch(`/api/v2/carts/${cartId}/distribution_status`, {
      method: 'GET',
    });
  }
}
